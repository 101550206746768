/**
 * Обработчик спиннера
 */
export class Spinner {
    constructor(object) {
        this.object = object
        this.value = object.innerHTML
    }

    start() {
        this.object.innerHTML = '<span class="loader"></span>'
        this.object.setAttribute('disabled', 'disabled')
    }

    end() {
        this.object.innerHTML = this.value
        this.object.removeAttribute('disabled')
    }
}
