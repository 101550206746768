import accountPhoto from "../../images/account.png";

import "../../styles/personalAccount.css";

import Pagination from "../pagination";
import TokenAvito from "../tokenAvito";
import Balance from "../balance";
import Referral from "../referral";

import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../store/GlobalContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Header from "../header.js";
import EditPersonalInfo from "../editPersonalInfo.js";
import ScrollToTop from "../../methods/scrollToTop.js";
import { Api } from "../../methods/requests/Api";

function PersonalAccount() {
  const {
    modal,
    setModal,
    setModalName,
    setModalData,
    setAlert,
    setAlertName,
    setAlertBorder,
    data,
    setSpiner,
    spiner,
  } = useContext(GlobalContext);

  const body = document.querySelector("body");

  const [allBalanceHistory, setAllBalanceHistory] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // Окно редактирования профиля
  function toggleEditProfile(e) {
    e.preventDefault();
    const componentEditProfile = document.querySelector(".edit");
    componentEditProfile.classList.toggle("hidden");
  }

  //   Алерт подтверждения выхода из аккаунта
  function confirmExitAccount(title) {
    setAlert(true);
    setAlertName(title);
    setAlertBorder("negative");
  }

  //   Модальное окно подробнее в истории
  const toggleModalHistory = (title, item) => {
    const modalName = title;
    setModalData(item);
    setModal(!modal);
    setModalName(modalName);

    if (modal === false) {
      body.style.overflow = "hidden";
    }
  };

  // Смена аккаунта
  const changeToken = (e) => {
    try {
      document.cookie = "currentToken=" + e.target.value + "; secure";
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  // Получение истории баланса
  const balanceHistory = async (page = 1) => {
    setSpiner(true);
    try {
      const response = await Api.getBalanceHistory(page);
      setAllBalanceHistory(response.data.data);
      setTotalPages(response.data.pagination.totalPage);
      setSpiner(false);
    } catch (err) {
      console.log(err);
      // setSpiner(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    balanceHistory(page); // Запросите данные для новой страницы
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? 'pagination-item pagination-item_active' : 'pagination-item'}
        >
          {i}
        </button>
      );
    }
    return <div className="pagination">{pages}</div>;
  };

  useEffect(() => {
    balanceHistory(currentPage);
  }, [currentPage]);

  const entries = Object.keys(localStorage);

  return (
    <div className="account">
      <ScrollToTop />
      <Header title={"Личный аккаунт"} />
      <div className="account-up">
        <div className="account-personal component">
          <h2>Личный аккаунт</h2>
          <div className="account-personal__content">
            <div className="account-content__left">
              <img src={accountPhoto} alt="Аватарка" />
              <div className="account-content__text">
                <div className="account-info">
                  <p className="account-email">{data.email}</p>
                  <p className="account-phone">{data.phone}</p>
                </div>

                <div className="account-content__buttons">
                  <button
                    onClick={toggleEditProfile}
                    className="button-default button-square"
                  >
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.7935 4.04006C18.1835 3.65006 18.1835 3.00006 17.7935 2.63006L15.4535 0.290059C15.0835 -0.0999414 14.4335 -0.0999414 14.0435 0.290059L12.2035 2.12006L15.9535 5.87006M0.0834961 14.2501V18.0001H3.8335L14.8935 6.93006L11.1435 3.18006L0.0834961 14.2501Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => confirmExitAccount("Выход из аккаунта")}
                    className="button-red button-square"
                  >
                    <svg
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.6429 1.5H1.5V18.125C1.5 18.7549 1.75587 19.359 2.21131 19.8044C2.66676 20.2498 3.28447 20.5 3.92857 20.5H13.6429M14.8571 14.5625L18.5 11M18.5 11L14.8571 7.4375M18.5 11H6.35714"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <label className="account-content__right" htmlFor="accountAvito">
              <span className="span-row">Авито аккаунт</span>
              <select
                name="accountAvito"
                className="select"
                onChange={changeToken}
              >
                <option>Выберете аккаунт</option>
                {entries.map((key) => (
                  // console.log(key, localStorage.getItem(key))
                  // console.log("");
                  <option key={key} value={localStorage.getItem(key)}>
                    {key}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <EditPersonalInfo />
        <TokenAvito />
      </div>

      <div className="account-bonus">
        <Balance />
        <Referral />
        <div className="account-links component">
          <a href="" className="account-links__item">
            <svg
              width="17"
              height="20"
              viewBox="0 0 17 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_645_3750)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.3335 0.5C10.3335 0.433696 10.3072 0.370107 10.2603 0.323223C10.2134 0.276339 10.1498 0.25 10.0835 0.25H3.0835C2.35415 0.25 1.65468 0.539731 1.13895 1.05546C0.623227 1.57118 0.333496 2.27065 0.333496 3V17C0.333496 17.7293 0.623227 18.4288 1.13895 18.9445C1.65468 19.4603 2.35415 19.75 3.0835 19.75H13.0835C13.8128 19.75 14.5123 19.4603 15.028 18.9445C15.5438 18.4288 15.8335 17.7293 15.8335 17V7.147C15.8335 7.0807 15.8072 7.01711 15.7603 6.97022C15.7134 6.92334 15.6498 6.897 15.5835 6.897H11.0835C10.8846 6.897 10.6938 6.81798 10.5532 6.67733C10.4125 6.53668 10.3335 6.34591 10.3335 6.147V0.5ZM11.0835 10.25C11.2824 10.25 11.4732 10.329 11.6138 10.4697C11.7545 10.6103 11.8335 10.8011 11.8335 11C11.8335 11.1989 11.7545 11.3897 11.6138 11.5303C11.4732 11.671 11.2824 11.75 11.0835 11.75H5.0835C4.88458 11.75 4.69382 11.671 4.55317 11.5303C4.41251 11.3897 4.3335 11.1989 4.3335 11C4.3335 10.8011 4.41251 10.6103 4.55317 10.4697C4.69382 10.329 4.88458 10.25 5.0835 10.25H11.0835ZM11.0835 14.25C11.2824 14.25 11.4732 14.329 11.6138 14.4697C11.7545 14.6103 11.8335 14.8011 11.8335 15C11.8335 15.1989 11.7545 15.3897 11.6138 15.5303C11.4732 15.671 11.2824 15.75 11.0835 15.75H5.0835C4.88458 15.75 4.69382 15.671 4.55317 15.5303C4.41251 15.3897 4.3335 15.1989 4.3335 15C4.3335 14.8011 4.41251 14.6103 4.55317 14.4697C4.69382 14.329 4.88458 14.25 5.0835 14.25H11.0835Z"
                  fill="white"
                />
                <path
                  d="M11.8335 0.823957C11.8335 0.639957 12.0265 0.522957 12.1695 0.637957C12.2905 0.735957 12.3995 0.849957 12.4925 0.979957L15.5055 5.17696C15.5735 5.27296 15.4995 5.39696 15.3815 5.39696H12.0835C12.0172 5.39696 11.9536 5.37062 11.9067 5.32373C11.8598 5.27685 11.8335 5.21326 11.8335 5.14696V0.823957Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_645_3750">
                  <rect
                    width="16"
                    height="20"
                    fill="white"
                    transform="translate(0.0834961)"
                  />
                </clipPath>
              </defs>
            </svg>
            Политика конфиденциальности
          </a>
          <a href="" className="account-links__item">
            <svg
              width="17"
              height="20"
              viewBox="0 0 17 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_645_3750)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.3335 0.5C10.3335 0.433696 10.3072 0.370107 10.2603 0.323223C10.2134 0.276339 10.1498 0.25 10.0835 0.25H3.0835C2.35415 0.25 1.65468 0.539731 1.13895 1.05546C0.623227 1.57118 0.333496 2.27065 0.333496 3V17C0.333496 17.7293 0.623227 18.4288 1.13895 18.9445C1.65468 19.4603 2.35415 19.75 3.0835 19.75H13.0835C13.8128 19.75 14.5123 19.4603 15.028 18.9445C15.5438 18.4288 15.8335 17.7293 15.8335 17V7.147C15.8335 7.0807 15.8072 7.01711 15.7603 6.97022C15.7134 6.92334 15.6498 6.897 15.5835 6.897H11.0835C10.8846 6.897 10.6938 6.81798 10.5532 6.67733C10.4125 6.53668 10.3335 6.34591 10.3335 6.147V0.5ZM11.0835 10.25C11.2824 10.25 11.4732 10.329 11.6138 10.4697C11.7545 10.6103 11.8335 10.8011 11.8335 11C11.8335 11.1989 11.7545 11.3897 11.6138 11.5303C11.4732 11.671 11.2824 11.75 11.0835 11.75H5.0835C4.88458 11.75 4.69382 11.671 4.55317 11.5303C4.41251 11.3897 4.3335 11.1989 4.3335 11C4.3335 10.8011 4.41251 10.6103 4.55317 10.4697C4.69382 10.329 4.88458 10.25 5.0835 10.25H11.0835ZM11.0835 14.25C11.2824 14.25 11.4732 14.329 11.6138 14.4697C11.7545 14.6103 11.8335 14.8011 11.8335 15C11.8335 15.1989 11.7545 15.3897 11.6138 15.5303C11.4732 15.671 11.2824 15.75 11.0835 15.75H5.0835C4.88458 15.75 4.69382 15.671 4.55317 15.5303C4.41251 15.3897 4.3335 15.1989 4.3335 15C4.3335 14.8011 4.41251 14.6103 4.55317 14.4697C4.69382 14.329 4.88458 14.25 5.0835 14.25H11.0835Z"
                  fill="white"
                />
                <path
                  d="M11.8335 0.823957C11.8335 0.639957 12.0265 0.522957 12.1695 0.637957C12.2905 0.735957 12.3995 0.849957 12.4925 0.979957L15.5055 5.17696C15.5735 5.27296 15.4995 5.39696 15.3815 5.39696H12.0835C12.0172 5.39696 11.9536 5.37062 11.9067 5.32373C11.8598 5.27685 11.8335 5.21326 11.8335 5.14696V0.823957Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_645_3750">
                  <rect
                    width="16"
                    height="20"
                    fill="white"
                    transform="translate(0.0834961)"
                  />
                </clipPath>
              </defs>
            </svg>
            Публичная оферта
          </a>
        </div>
      </div>

      <div className="payment-history component" id="payment-history">
        <h2>История платежей</h2>
        {spiner ? (
          <div className="skeleton-table__list">
            <div className="skeleton-table-item">
              <div className="skeleton-table__left">
                <div className="block pulsate"></div>
                <div className="skeleton-table__description">
                  <div className="block pulsate"></div>
                  <div className="block pulsate"></div>
                </div>
              </div>
              <div className="skeleton-table__buttons">
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table-item">
              <div className="skeleton-table__left">
                <div className="block pulsate"></div>
                <div className="skeleton-table__description">
                  <div className="block pulsate"></div>
                  <div className="block pulsate"></div>
                </div>
              </div>
              <div className="skeleton-table__buttons">
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table-item">
              <div className="skeleton-table__left">
                <div className="block pulsate"></div>
                <div className="skeleton-table__description">
                  <div className="block pulsate"></div>
                  <div className="block pulsate"></div>
                </div>
              </div>
              <div className="skeleton-table__buttons">
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table-item">
              <div className="skeleton-table__left">
                <div className="block pulsate"></div>
                <div className="skeleton-table__description">
                  <div className="block pulsate"></div>
                  <div className="block pulsate"></div>
                </div>
              </div>
              <div className="skeleton-table__buttons">
                <div className="block pulsate"></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="payment-history__list">
            {allBalanceHistory &&
              allBalanceHistory.map((item) => (
                <div className="payment-list__item" key={item.id}>
                  <div className="payment-item__info">
                    <div className="payment-item-info__service">
                      <div className="payment-circle">
                        <svg
                          width="11"
                          height="12"
                          viewBox="0 0 11 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.417813 12L4.32181 6.176L0.529813 0.48H2.88981L5.51381 4.536L8.11381 0.48H10.4898L6.69781 6.176L10.5858 12H8.22581L5.51381 7.816L2.79381 12H0.417813Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="payment-item-info__text">
                      <h3>
                        {item.type === "Баланс"
                          ? item.action + " " + "баланса"
                          : item.action + " " + "бонусов"}
                      </h3>
                      <div
                        className={
                          item.action === "Списание"
                            ? "payment-more__price_mobile payment-more__price_negative"
                            : "payment-more__price_positive payment-more__price_mobile"
                        }
                      >
                        {item.action === "Списание"
                          ? "-" + item.value_total
                          : "+" + item.value_total}{" "}
                        ₽
                      </div>
                    </div>
                  </div>
                  <p className="payment-item-info__date">{item.date}</p>
                  <div className="payment-item__more">
                    <button
                      className="button-default"
                      onClick={() =>
                        toggleModalHistory(
                          item.action === "Списание"
                            ? "История оплаты задачи"
                            : "История пополнения счёта",
                          item
                        )
                      }
                    >
                      Подробнее
                    </button>
                  </div>
                </div>
              ))}
          </div>
        )}

        {totalPages > 1 ? (
          renderPagination()
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default PersonalAccount;
