import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../store/GlobalContext";

import "../../styles/modal.css";

import Improver from "./improver";
import PaymentTasks from "./paymentTasks";
import ChangePassword from "./changePassword";
import TopUpBalance from "./topUpBalance";
import HistoryPaymentTask from "./historyPaymentTask";
import HistoryTopUpBalance from "./historyTopUpBalance";
import AddRole from "./addRole";

const Modal = (url) => {
  const { modal, setModal, modalName, setSpiner } = useContext(GlobalContext);
  const body = document.querySelector("body");

  // Закрытие модального окна при нажатии на крестик
  const closeModal = () => {
    setModal(false);
    body.style.overflow = "auto";
  };

// Закрытие модального окна при нажатии вне модального окна
  const checkModal = (target) => {
    if (
      target.classList.contains("modal-wrapper") ||
      target.parentElement.classList.contains("modal-wrapper")
    ) {
      setModal(false);
      body.style.overflow = "auto";
    }
  };

  // Закрытие модального окна при escape
  const onKeypress = (e) => {
    if (e.code === "Escape") {
      setModal(false);
      body.style.overflow = "auto";
    }
  };
  
  document.addEventListener("keydown", onKeypress);
  
  // Закрытие модального окна
  useEffect(() => {
    const modalWrapper = document.querySelector(".modal-wrapper");
    const click = (e) => checkModal(e.target);
    body.style.overflow = "auto";
    modalWrapper.addEventListener("click", click);
  }, [body]);

  return (
    <div className={modal ? "modal-wrapper" : "modal-wrapper hidden"}>
        <div className="modal component">
          <div className="modal-close" onClick={closeModal}>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.4503 1.065C19.3116 0.925945 19.1467 0.815623 18.9653 0.740351C18.7838 0.665079 18.5893 0.626333 18.3928 0.626333C18.1964 0.626333 18.0019 0.665079 17.8204 0.740351C17.6389 0.815623 17.4741 0.925945 17.3353 1.065L10.0003 8.385L2.66534 1.05C2.52646 0.911127 2.3616 0.800967 2.18015 0.725809C1.9987 0.650652 1.80423 0.611969 1.60784 0.611969C1.41144 0.611969 1.21697 0.650652 1.03552 0.725809C0.854074 0.800967 0.689208 0.911127 0.550335 1.05C0.411462 1.18887 0.301302 1.35374 0.226145 1.53518C0.150987 1.71663 0.112305 1.9111 0.112305 2.1075C0.112305 2.3039 0.150987 2.49837 0.226145 2.67981C0.301302 2.86126 0.411462 3.02613 0.550335 3.165L7.88534 10.5L0.550335 17.835C0.411462 17.9739 0.301302 18.1387 0.226145 18.3202C0.150987 18.5016 0.112305 18.6961 0.112305 18.8925C0.112305 19.0889 0.150987 19.2834 0.226145 19.4648C0.301302 19.6463 0.411462 19.8111 0.550335 19.95C0.689208 20.0889 0.854074 20.199 1.03552 20.2742C1.21697 20.3493 1.41144 20.388 1.60784 20.388C1.80423 20.388 1.9987 20.3493 2.18015 20.2742C2.3616 20.199 2.52646 20.0889 2.66534 19.95L10.0003 12.615L17.3353 19.95C17.4742 20.0889 17.6391 20.199 17.8205 20.2742C18.002 20.3493 18.1964 20.388 18.3928 20.388C18.5892 20.388 18.7837 20.3493 18.9651 20.2742C19.1466 20.199 19.3115 20.0889 19.4503 19.95C19.5892 19.8111 19.6994 19.6463 19.7745 19.4648C19.8497 19.2834 19.8884 19.0889 19.8884 18.8925C19.8884 18.6961 19.8497 18.5016 19.7745 18.3202C19.6994 18.1387 19.5892 17.9739 19.4503 17.835L12.1153 10.5L19.4503 3.165C20.0203 2.595 20.0203 1.635 19.4503 1.065Z"
                fill="black"
              />
            </svg>
          </div>

          {modalName === "Выбор объявлений" && <Improver />}

          {modalName === "Активные задачи" && <Improver />}

          {modalName === "Корзина задач" && <Improver />}

          {modalName === "Завершенные задачи" && <Improver />}

          {modalName === "Оплата задачи" && <PaymentTasks />}

          {modalName === "Смена пароля" && <ChangePassword />}

          {modalName === "Пополнить баланс" && <TopUpBalance />}

          {modalName === "История оплаты задачи" && <HistoryPaymentTask />}

          {modalName === "История пополнения счёта" && <HistoryTopUpBalance />}

          {modalName === "Добавление роли" && <AddRole />}
        </div>
    </div>
  );
};

export default Modal;
