import "../../styles/header.css";
import "../../styles/homeScreen.css";

import baner from "../../images/adv.png";

import Header from "../header";
import Balance from "../balance";
import Referal from "../referral";
import TableProducts from "../tableProducts/tableProducts";
import { Link } from "react-router-dom";
import ScrollToTop from "../../methods/scrollToTop";
import AllTasks from "../allTasks";
import ActiveTasks from "../activeTasks";

function HomeScreen() {
  return (
    <div className="home-screen">
      <ScrollToTop/>
      <Header title={"Главная"} />

      <div className="dashboard-up">
        <div className="dashboard-item dashboard-item__adv">
          <img src={baner} alt="baner" />
        </div>
        <AllTasks></AllTasks>
        <ActiveTasks></ActiveTasks>
      </div>
      <div className="dashboard-down">
        <div className="dashboard-item component dashboard-item__create-tasks">
          <Link to="/create-task" className="button-width_auto">
            <button className="button-default">Создать новую задачу</button>
          </Link>
        </div>
        <Balance />
        <Referal />
        <div className="dashboard-item component dashboard-item__history-payments">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 0C5.3724 0 0 5.3724 0 12C0 18.6276 5.3724 24 12 24C18.6276 24 24 18.6276 24 12C24 5.3724 18.6276 0 12 0ZM7.8 9C7.8 8.44845 7.90864 7.9023 8.11971 7.39273C8.33078 6.88316 8.64015 6.42016 9.03015 6.03015C9.42016 5.64015 9.88316 5.33078 10.3927 5.11971C10.9023 4.90864 11.4484 4.8 12 4.8C12.5516 4.8 13.0977 4.90864 13.6073 5.11971C14.1168 5.33078 14.5798 5.64015 14.9698 6.03015C15.3599 6.42016 15.6692 6.88316 15.8803 7.39273C16.0914 7.9023 16.2 8.44845 16.2 9C16.2 10.1139 15.7575 11.1822 14.9698 11.9698C14.1822 12.7575 13.1139 13.2 12 13.2C10.8861 13.2 9.81781 12.7575 9.03015 11.9698C8.2425 11.1822 7.8 10.1139 7.8 9ZM19.5096 17.9808C18.6113 19.1107 17.4694 20.0231 16.169 20.6498C14.8686 21.2765 13.4435 21.6013 12 21.6C10.5565 21.6013 9.13136 21.2765 7.831 20.6498C6.53064 20.0231 5.38868 19.1107 4.4904 17.9808C6.4356 16.5852 9.09 15.6 12 15.6C14.91 15.6 17.5644 16.5852 19.5096 17.9808Z"
              fill="#0B99FF"
            />
          </svg>
          <p>История платежей</p>
          <Link to="/account#payment-history" className="button-width_full">
            <button className="button-default">Перейти</button>
          </Link>
        </div>
      </div>
      <TableProducts title="Активные задачи" />
    </div>
  );
}
export default HomeScreen;
