import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../store/GlobalContext";
import { Api } from "../../methods/requests/Api";
import "../../styles/pagination.css";

import announcPhoto from "../../images/house1.png";

const SelectTasksTable = ({ title }) => {
  const {
    modal,
    setModal,
    setModalName,
    setTaskId,
    data,
    allAnnouncement,
    setAllAnnouncement,
    spiner,
    setSpiner,
    setCurrentTask,
  } = useContext(GlobalContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const body = document.querySelector("body");

  const toggleModal = (modalName, item) => {
    setModal(!modal);
    setModalName(modalName);
    setCurrentTask(item);
    if (modal === false) {
      body.style.overflow = "hidden";
    }
  };

  const getAnnouncementInfo = async (page = 1) => {
    try {
      setSpiner(true);
      if (data.avito_token && data.avito_token != 0) {
        const response = await Api.getAnnouncementInfo();
        setAllAnnouncement(response.data.data);
        // setTotalPages(response.data.pagination.totalPage);
        setSpiner(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = () => {
    setCurrentPage();
    getAnnouncementInfo(); // Запросите данные для новой страницы
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={
            currentPage === i
              ? "pagination-item pagination-item_active"
              : "pagination-item"
          }
        >
          {i}
        </button>
      );
    }
    return <div className="pagination">{pages}</div>;
  };
  // Массив для заглушки, для тех у кого нет токена
  const blurArray = [
    {
      id: 1,
      image: announcPhoto,
      title: "Дом 40 м² на участке 5 сот.",
      price: "12000000",
      address: "Республика Коми",
    },
    {
      id: 2,
      image: announcPhoto,
      title: "Дом 40 м² на участке 5 сот.",
      price: "12000000",
      address: "Республика Коми",
    },
  ];

  useEffect(() => {
    Object.keys(allAnnouncement).length === 0 &&
      getAnnouncementInfo();
  }, []);

  return (
    <div className="table-products__content">
      {data.avito_token &&
      data.avito_token != 0 &&
      spiner ? (
        <div className="skeleton-table__list">
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="table-products__list">
          {allAnnouncement &&
            allAnnouncement.map((item) => (
              <div className="table-item" key={item.id}>
                <div className="table-item__left">
                  {/* <img
                    className="table-item__img"
                    src={item.image}
                    alt="Фото объявления"
                  /> */}
                  <div className="table-text__container">
                    <a href={item.url}>
                      <h3 className="table-item__title">{item.title}</h3>
                    </a>
                    <div className="table-text__description">
                      <p className="table-item__text_price">{item.price} ₽</p>
                      <p className="table-item__id">ID: {item.id}</p>
                      <p className="table-item__location">{item.address}</p>
                    </div>
                  </div>
                </div>
                <div className="table-item__buttons">
                  <button
                    onClick={() => toggleModal(title, item)}
                    className="button-default"
                  >
                    Выбрать
                  </button>
                </div>
              </div>
            ))}
        </div>
      )}
      {data.avito_token && data.avito_token != 0 ? (
        ""
      ) : (
        <div className="table-products_banned">
          <div className="table-products__blur">
            <h2>Введите токен, чтобы посмотреть</h2>
          </div>
          <div className="table-products__list">
            {blurArray &&
              blurArray.map((item) => (
                <div className="table-item" key={item.id}>
                  <div className="table-item__left">
                    <img
                      className="table-item__img"
                      src={item.image}
                      alt="Фото объявления"
                    />
                    <div className="table-text__container">
                      <a href={item.url}>
                        <h3 className="table-item__title">{item.title}</h3>
                      </a>
                      <div className="table-text__description">
                        <p className="table-item__text_price">{item.price} ₽</p>
                        <p className="table-item__id">ID: {item.id}</p>
                        <p className="table-item__location">{item.address}</p>
                      </div>
                    </div>
                  </div>
                  <div className="table-item__buttons">
                    <button className="button-default">Выбрать</button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectTasksTable;
