import axios from "axios";
import { getCookie } from "../getCookie";
export class Api {
  static auth() {
    return {
      headers: {
        Authorization: "Bearer " + getCookie("currentToken"),
      },
    };
  }

  // Переменная для api + route
  static baseUrl(route) {
    return "https://api.avibooster.ru/api/" + route;
  }

  // Вывод услуг
  static async indexService() {
    const response = await axios.get(this.baseUrl("services"), this.auth());
    return response;
  }

  // Вывод цен услуг
  static async getServicesPrices(id) {
    const response = await axios.get(
      this.baseUrl("announcements/price/" + id),
      this.auth()
    );
    return response;
  }

  // Проверка авторизаци
  static async checkAuth() {
    const response = await axios.get(this.baseUrl("auth"), this.auth());
    return response;
  }

  // Регистрация пользователя
  static async registrationUser(data, token) {
    if (token) {
      const response = await axios.post(
        this.baseUrl("auth/registration?token=" + token),
        data
      );
      return response;
    } else {
      const response = await axios.post(
        this.baseUrl("auth/registration"),
        data
      );
      return response;
    }
  }

  // Отправка подтверждения почты
  static async sendVerified(login) {
    const response = await axios.post(this.baseUrl("auth/sendverified"), {
      login: login,
    });
    return response;
  }

  // Уведомления
  static async notification() {
    const response = await axios.get(
      this.baseUrl("notifications/default"),
      this.auth()
    );
    return response;
  }

  static async notificationСount() {
    const response = await axios.get(
      this.baseUrl("notifications/count-unwatched"),
      this.auth()
    );
    return response;
  }

  static async notificationVieved() {
    const response = await axios.get(
      this.baseUrl("notifications/viewed"),
      this.auth()
    );
    return response;
  }

  // Редактирование пользовательской информации
  static async editPersonalInfo(data, id) {
    const response = await axios.patch(
      this.baseUrl("users/" + id),
      data,
      this.auth()
    );
    return response;
  }

  static async editPersonalPassword(data) {
    const response = await axios.post(
      this.baseUrl("users/password"),
      data,
      this.auth()
    );
    return response;
  }

  // Востановление пароля
  static async sendRecoveryMessage(email) {
    const response = await axios.post(
      this.baseUrl("auth/recoverypassword"),
      {
        login: email,
      },
      this.auth()
    );
    return response;
  }

  static async passwordRecovery(password, token) {
    const response = await axios.post(
      this.baseUrl("users/password/recovery?token=" + token),
      {
        password: password,
      }
    );
    return response;
  }

  // Токен Авито
  static async sendTokenAvitoClientId(data) {
    const responseClientId = await axios.post(
      this.baseUrl("tokens"),
      {
        token: data.client_id,
        type: "client_id",
      },
      this.auth()
    );
    return responseClientId;
  }

  static async sendTokenAvitoClientSecret(data) {
    const responseClientSecret = await axios.post(
      this.baseUrl("tokens"),
      {
        token: data.client_secret,
        type: "client_secret",
      },
      this.auth()
    );
    return responseClientSecret;
  }

  // Редактирование Токена Авито
  static async editTokenAvito(data) {
    const response = await axios.patch(
      this.baseUrl("tokens/" + data.id),
      {
        token: data.token,
      },
      this.auth()
    );
    return response;
  }

  // Получение данных об объявлении Авито
  static async getAnnouncementInfo(page = 1) {
      const response = await axios.get(
        this.baseUrl("announcements/"),
        this.auth()
      );
      return response;
  }

  // Получение данных об объявлении Авито по ссылке
  static async getAnnouncementInfoUrl(url) {
    const response = await axios.post(
      this.baseUrl("announcements/show"),
      {
        url: url,
      },
      this.auth()
    );
    return response;
  }

  // Добавление задачи в корзину
  static async createTask(taskInfo) {
    const response = await axios.post(
      this.baseUrl("baskets"),
      {
        service_id: taskInfo.service_id,
        duration: taskInfo.duration,
        avito_id: taskInfo.avito_id,
        avito_link: taskInfo.avito_link,
        title: taskInfo.title,
        image: taskInfo.image,
        address: taskInfo.address,
        price_product: taskInfo.price_product,
        category: taskInfo.category,
      },
      this.auth()
    );
    return response;
  }

  // Добавление задачи в корзину
  static async editTask(taskInfo) {
    const response = await axios.post(
      this.baseUrl("baskets/" + taskInfo.id),
      {
        service_id: taskInfo.service_id,
        duration: taskInfo.duration,
      },
      this.auth()
    );
    return response;
  }

  // Получить содержимое корзины
  static async getBasket(page = 1) {
    const response = await axios.get(this.baseUrl("baskets/?page=" + page), 
    this.auth());
    return response;
  }

  // Удалить задачу корзины по id
  static async deleteTaskBasket(taskId) {
    const response = await axios.delete(
      this.baseUrl("baskets/" + taskId),
      this.auth()
    );
    return response;
  }

  // Узнать цену корзины
  static async sumBasket() {
    const response = await axios.get(
      this.baseUrl("baskets/price"),
      this.auth()
    );
    return response;
  }

  // Создать заказ
  static async createOrder(bonus) {
    const response = await axios.post(
      this.baseUrl("orders"),
      { bonus: bonus },
      this.auth()
    );
    return response;
  }

  // Посмотреть историю баланса
  static async getBalanceHistory(page = 1) {
    const response = await axios.get(
      this.baseUrl("balances/history/?page=" + page),
      this.auth()
    );
    return response;
  }


  // Посмотреть количество всех заказов
  static async getAllTasks() {
    const response = await axios.get(
      this.baseUrl("statistic/all"),
      this.auth()
    );
    return response;
  }

  // Посмотреть количество активных заказов
  static async getActiveTasks() {
    const response = await axios.get(
      this.baseUrl("statistic/active"),
      this.auth()
    );
    return response;
  }

  // Посмотреть активные заказы пользователя
  static async getAllActiveTasks(page = 1) {
    const response = await axios.get(
      this.baseUrl("orders/user/active?page=" + page),
      this.auth()
    );
    return response;
  }

  // Посмотреть завершенные заказы пользователя
  static async getAllFinallyTasks() {
    const response = await axios.get(
      this.baseUrl("orders/user/finally"),
      this.auth()
    );
    return response;
  }
}
