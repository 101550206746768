import { Link, useLocation } from "react-router-dom";

import "../../styles/tableProducts.css";

import SelectTasksTable from "./selectTasksTable";
import CartTasksTable from "./cartTasksTable";
import ActiveTasksTable from "./activeTasksTable";
import CompletedTasksTable from "./completedTasksTable";
import Pagination from "../pagination";

function TableProducts({ title }) {
  const location = useLocation();

  return (
    <div className="component">
      <h2>{title}</h2>
      <div className="table-products">
        <div className="table-products__header">
          {/* <select name="accountAvito" id="" className="select">
            <option value="">Москва</option>
            <option value="">Сыктывкар</option>
          </select>
          <div className="search">
            <input className="input-search" placeholder="Поиск" />
            <button className="button-search">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.0002 19L14.6572 14.657M14.6572 14.657C15.4001 13.9141 15.9894 13.0321 16.3914 12.0615C16.7935 11.0909 17.0004 10.0506 17.0004 8.99996C17.0004 7.94936 16.7935 6.90905 16.3914 5.93842C15.9894 4.96779 15.4001 4.08585 14.6572 3.34296C13.9143 2.60007 13.0324 2.01078 12.0618 1.60874C11.0911 1.20669 10.0508 0.999756 9.00021 0.999756C7.9496 0.999756 6.90929 1.20669 5.93866 1.60874C4.96803 2.01078 4.08609 2.60007 3.34321 3.34296C1.84288 4.84329 1 6.87818 1 8.99996C1 11.1217 1.84288 13.1566 3.34321 14.657C4.84354 16.1573 6.87842 17.0002 9.00021 17.0002C11.122 17.0002 13.1569 16.1573 14.6572 14.657Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div> */}
        </div>

        {title === "Выбор объявлений" && (
          <SelectTasksTable title={title}/>
        )}
        {title === "Корзина задач" && (
          <CartTasksTable title={title}/>
        )}
        {title === "Активные задачи" && (
          <ActiveTasksTable title={title}/>
        )}
        {title === "Завершенные задачи" && (
          <CompletedTasksTable title={title}/>
        )}

        {location.pathname === "/home" && (
          <Link to="/my-tasks" className="button-width_full">
            <button className="button-default">Подробнее</button>
          </Link>
        )}
        {/* {!(location.pathname === "/home") && <Pagination />} */}
      </div>
    </div>
  );
}
export default TableProducts;
