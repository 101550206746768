import { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { GlobalContext } from "../../store/GlobalContext";
import Aside from "../aside";
import NavMenu from "../navMenu";
import Modal from "../modals/modal";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { status, data } = useContext(GlobalContext);

  if (status && status !== 200) {
    return <Navigate to="/login" state={{ from: location }} />;
  }else {

    return (
      <div className={data.role_id === 1 ? "" : "App-content"}>
        {!(
          window.location.pathname === "/login" ||
          window.location.pathname === "/registration" ||
          window.location.pathname === "/password-recovery" ||
          window.location.pathname === "/admin" ||
          data.role_id === 1
        ) ? (
          <Aside />
        ) : null}
        <div className={data.role_id === 1 ? "admin-container" : "container"}>{children}</div>
        <Modal />
        {!(
          window.location.pathname === "/login" ||
          window.location.pathname === "/registration" ||
          window.location.pathname === "/password-recovery" ||
          window.location.pathname === "/admin" ||
          data.role_id === 1
        ) ? (
          <NavMenu />
        ) : null}
      </div>
    );
  }

};

export { RequireAuth };
