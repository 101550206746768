import { useContext } from "react";
import { GlobalContext } from "../store/GlobalContext";

import "../styles/balance.css";

function Balance() {
  const { modal, setModal, setModalName, data } = useContext(GlobalContext);

  function toggleModal(e) {
    const modalName = "Пополнить баланс";
    const body = document.querySelector("body");
    e.preventDefault();
    setModal(!modal);
    setModalName(modalName);
    if (modal === false) {
      body.style.overflow = "hidden";
    }
  }

  return (
    <div className="component balance">
      <div className="balance-up">
        <div className="balance-left">
          <h2>Баланс</h2>
          <p className="balance-title">{data.balance} ₽</p>
        </div>
        <p className="balance-bonus">Бонусы: {data.bonus}</p>
      </div>
      <button onClick={toggleModal} className="button-default">
        Пополнить
      </button>
    </div>
  );
}

export default Balance;
