import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../store/GlobalContext";

function PageNotFound() {
    
  const { status, data } = useContext(GlobalContext);
    if (status && status === 200 && data.role_id !== 1) {
        return <Navigate to="/home" />;
    } else if (status && status !== 200){
        return <Navigate to="/login" />;
    }

    if(data.role_id && data.role_id === 1){
        return <Navigate to="/" />;
    }
}

export default PageNotFound