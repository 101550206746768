import { GlobalProvider } from './store/GlobalContext'
import Container from './components/Container'

function App() {
    return (
        <GlobalProvider>
            <Container/>
        </GlobalProvider>
    );
}

export default App;