import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../store/GlobalContext";
import { Api } from "../../methods/requests/Api";
import { Spinner } from "../../methods/spinner";

const PaymentTasks = () => {
  const {
    data,
    allBasket,
    priceBasket,
    setAlert,
    setAlertBorder,
    setAlertName,
    modal,
    setModal,
    sum,
    setSum,
    setAllBasket,
    setSpiner,
  } = useContext(GlobalContext);
  const [value, setValue] = useState(0);

  const bonusUser = data.bonus - 1;
  
  // setSum(priceBasket);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const services = {
    1: ["x5", "payment-item__number_orange"],
    2: ["x10", "payment-item__number_green"],
    3: ["x20", "payment-item__number_violet"],
    4: ["x30", "payment-item__number_red"],
  };

  // Оформление заказа
  const createOrder = async () => {
    const btn = new Spinner(
      document.querySelector(".payment-row .button-active")
    );
    btn.start();
    try {
      await Api.createOrder(value);
      setSpiner(true);

      const response = await Api.getBasket();
      setAllBasket(response.data.data);
      setSpiner(false);
      
      setModal(!modal);
      setAlert(true);
      setAlertBorder("positive");
      setAlertName("Оплата прошла");
    } catch (err) {
      if (err === 400) {
        setAlert(true);
        setAlertName("Недостаточно средств");
        setAlertBorder("negative");
      }
    }
    btn.end();
  };

  // Кастомный ползунок
  const rangeInputSlider = () => {
    const rangeThumb = document.getElementById("range-thumb"),
      rangeLine = document.getElementById("range-line"),
      rangeInput = document.getElementById("range-input"),
      arrow = document.querySelector(".arrow");
    // setValue(value);
    const thumbPosition = rangeInput.value / rangeInput.max,
      space = rangeInput.offsetWidth - rangeThumb.offsetWidth;

    rangeThumb.style.left = thumbPosition * space + "px";
    rangeLine.style.width = (rangeInput.value * 100) / rangeInput.max + "%";
    arrow.style.left = (rangeInput.value * 100) / rangeInput.max + "%";

    rangeInput.addEventListener("input", rangeInputSlider);
  };

  const totalSum = () => {
    setSum(priceBasket - value);
  };

  useEffect(() => {
    rangeInputSlider();
  }, []);

  useEffect(() => {
    totalSum();
  }, [handleChange, value]);

  return (
    <div className="modal-content">
      <h1>Оплата задачи</h1>
      <div className="payment-list">
        {allBasket &&
          allBasket.map((item) => (
            <div className="payment-item" key={item.id}>
              <div className="payment-item__left">
                <p className="payment-text__second">Id: {item.avito_id}</p>

                <p
                  className={
                    services
                      ? "payment-item__number " + services[item.service_id][1]
                      : ""
                  }
                >
                  {services[item.service_id][0]}
                </p>
                <p className="payment-text__second">
                  Дата окончания: {item.finish_time}
                </p>
                <p className="payment-text__second">
                  Срок: {item.duration} дней
                </p>
              </div>
              <p>{item.total_price} ₽</p>
            </div>
          ))}
        <p className="payment-sum">Итог: {priceBasket} ₽</p>
      </div>
      <h3>Выбор способа пополнения</h3>
      <div className="payment-row">
        <select name="accountAvito" id="" className="select select-width_full">
          <option value="">Безналичный расчет</option>
          <option value="">Безналичный расчет</option>
          <option value="">Безналичный расчет</option>
          <option value="">Безналичный расчет</option>
        </select>
        <input placeholder="Промокод" />
      </div>
      {value ? (
        <h3>
          Списать бонусы - {value}{" "}
          {value === 1
            ? "бонус"
            : value === 2
            ? "бонуса"
            : value === 3
            ? "бонуса"
            : value === 4
            ? "бонуса"
            : "бонусов"}
        </h3>
      ) : (
        <h3>Выберите сколько списать бонусов</h3>
      )}

      <div className="modal-range">
        <div className="range">
          <div className="range__content">
            <div className="range__slider">
              <div className="range__slider-line" id="range-line"></div>
            </div>

            <div className="range__thumb" id="range-thumb">
              <div className="arrow">
                <svg
                  width="19"
                  height="26"
                  viewBox="0 0 19 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.8351 23.3259L11.8351 23.3259L11.8367 23.3216L18.1162 6.12073C18.1164 6.12015 18.1166 6.11958 18.1168 6.11901C18.299 5.62662 18.3909 5.07964 18.3909 4.53147C18.3909 3.98325 18.299 3.43623 18.1168 2.9438C17.9319 2.43826 17.652 1.98111 17.2789 1.64485C16.9023 1.30544 16.4319 1.09346 15.9158 1.09974L3.36125 1.09974C2.84511 1.09346 2.37476 1.30544 1.99814 1.64485C1.62508 1.98105 1.34524 2.43811 1.16035 2.94356C0.977901 3.43576 0.885719 3.98263 0.885423 4.53078C0.885132 5.0791 0.976783 5.6263 1.15876 6.11896C1.15893 6.11941 1.15909 6.11986 1.15926 6.12031L7.4404 23.3216L7.44039 23.3216L7.44197 23.3259C7.62925 23.8287 7.90961 24.2826 8.281 24.6179C8.65664 24.9569 9.12435 25.1707 9.63853 25.1707C10.1527 25.1707 10.6204 24.9569 10.9961 24.6179C11.3674 24.2826 11.6478 23.8287 11.8351 23.3259Z"
                    fill="#36B14A"
                    stroke="white"
                    strokeWidth="1.41463"
                  />
                </svg>
              </div>
            </div>
            <input
              type="range"
              className="range__input"
              name="promotion-period"
              id="range-input"
              min="0"
              max={bonusUser >= priceBasket ? priceBasket - 1 : bonusUser}
              value={value}
              onChange={handleChange}
              step="1"
            />
          </div>
        </div>
        <div className="modal-range__numbers">
          <p>0</p>
          <p>{priceBasket - 1}</p>
        </div>
      </div>
      <div className="payment-row">
        <h2>Стоимость продвижения: {sum} ₽ </h2>
        <button
          className="button-active button-width_auto"
          onClick={createOrder}
        >
          Оплатить
        </button>
      </div>
    </div>
  );
};

export default PaymentTasks;
