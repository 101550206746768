import "../../../styles/landing/landing.css";

import Intro from "./intro";
import Functions from './functions';
import Steps from './steps';
import Reviews from './reviews';
import Advantages from './advantages';
import Footer from './footer';
import Header from './header';
import Start from "./start";

function Landing() {
  return (
    <div className="landing">
      <Header />
      <main>
        <Intro />
        <Functions />
        <Advantages />
        <Steps />
        <Reviews />
        <Start />
      </main>
      <Footer />
    </div>
  );
}
export default Landing;
