import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../store/GlobalContext";
import { Api } from "../../methods/requests/Api";

const ActiveTasksTable = ({ title }) => {
  const { spiner, setSpiner } = useContext(GlobalContext);

  const [activeTasks, setActiveTasks] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getActiveTasks = async (page = 1) => {
    setSpiner(true);
    try {
      const response = await Api.getAllActiveTasks(page);
      setActiveTasks(response.data.data);
      setTotalPages(response.data.pagination.totalPage);
      setSpiner(false);
    } catch {}
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getActiveTasks(page); // Запросите данные для новой страницы
  };
  
  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? 'pagination-item pagination-item_active' : 'pagination-item'}
        >
          {i}
        </button>
      );
    }
    return <div className="pagination">{pages}</div>;
  };

  useEffect(() => {
    getActiveTasks(currentPage);
  }, [currentPage]);

  const services = {
    1: ["x5", "payment-item__number_orange"],
    2: ["x10", "payment-item__number_green"],
    3: ["x20", "payment-item__number_violet"],
    4: ["x30", "payment-item__number_red"],
  };

  return (
    <div className="table-products__content">
      {spiner ? (
        <div className="skeleton-table__list">
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="table-products__list">
        {activeTasks &&
          activeTasks.map((item) => (
            <div className="table-item" key={item.id}>
              <div className="table-item__left">
                {/* <img
                className="table-item__img"
                src={item.image}
                alt="Фото объявления"
              /> */}
                <div className="table-text__container">
                  <a href={item.avito_link}>
                    <h3 className="table-item__title">{item.title}</h3>
                  </a>
                  <div className="table-text__description">
                    <p className="table-item__text_price">
                      {item.price_product} ₽
                    </p>
                    <p className="table-item__id">ID: {item.avito_id}</p>
                    <p className="table-item__location">{item.address}</p>
                  </div>
                </div>
              </div>
              <div className="table-item__right">
                <p className="table-item__date">
                  Дата окончания: {item.finish_time}
                </p>
                <p
                  className={
                    services
                      ? "payment-item__number " + services[item.service_id][1]
                      : ""
                  }
                >
                  {services[item.service_id][0]}
                </p>
                <div className="table-progressbar">
                  <p>{item.duration - item.duration_left} / {item.duration} дней</p>
                  <div className="table-progressbar__strip" style={{width:((item.duration - item.duration_left) / item.duration * 100)+'%'}}></div>
                </div>
              </div>
            </div>
          ))}
      </div>
      )}

      {totalPages > 1 ? (
        renderPagination()
      ) : (
        ""
      )}
    </div>
  );
};

export default ActiveTasksTable;
