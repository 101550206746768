import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../store/GlobalContext";
import { Api } from "../../methods/requests/Api";

const CompletedTasksTable = ({ title }) => {
  const { spiner, setSpiner } = useContext(GlobalContext);

  const [finallyTasks, setFinallyTasks] = useState([]);
  const [pagination, setPagination] = useState(1);

  const getFinallyTasks = async () => {
    setSpiner(true);
    try {
      const response = await Api.getAllFinallyTasks();
      setFinallyTasks(response.data.data);
      setPagination(response.data.pagination.totalPage);
      setSpiner(false);
    } catch {}
  };

  useEffect(() => {
    getFinallyTasks();
  }, []);

  const services = {
    1: ["x5", "payment-item__number_orange"],
    2: ["x10", "payment-item__number_green"],
    3: ["x20", "payment-item__number_violet"],
    4: ["x30", "payment-item__number_red"],
  };

  return (
    <div>
      {Object.keys(finallyTasks).length == 0 && spiner ? (
        <div className="skeleton-table__list">
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="table-products__list">
        {finallyTasks &&
          finallyTasks.map((item) => (
            <div className="table-item" key={item.id}>
              <div className="table-item__left">
                {/* <img
            className="table-item__img"
            src={item.image}
            alt="Фото объявления"
          /> */}
                <div className="table-text__container">
                  <a href={item.avito_link}>
                    <h3 className="table-item__title">{item.title}</h3>
                  </a>
                  <div className="table-text__description">
                    <p className="table-item__text_price">
                      {item.price_product} ₽
                    </p>
                    <p className="table-item__id">ID: {item.avito_id}</p>
                    <p className="table-item__location">{item.address}</p>
                  </div>
                </div>
              </div>
              <div className="table-item__right">
                <p className="table-item__date">
                  Дата окончания: {item.finish_time}
                </p>
                <p
                  className={
                    services
                      ? "payment-item__number " + services[item.service_id][1]
                      : ""
                  }
                >
                  {services[item.service_id][0]}
                </p>
              </div>
            </div>
          ))}
      </div>
      {pagination > 1 ? (
        <div className="pagination">
          <button className="pagination-item">
            <svg
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.992 7.712L0.24 3.96L3.992 0.207999L4.744 0.943999L1.688 3.96L4.744 6.976L3.992 7.712Z"
                fill="black"
              />
            </svg>
          </button>
          <button className="pagination-item">
            <svg
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.832 7.712L0.0880001 6.976L3.136 3.96L0.0880001 0.943999L0.832 0.207999L4.592 3.96L0.832 7.712Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CompletedTasksTable;
