import { Link } from "react-router-dom";
import { GlobalContext } from "../../store/GlobalContext";
import { Api } from "../../methods/requests/Api";
import { useContext, useEffect, useState } from "react";

import ScrollToTop from "../../methods/scrollToTop";
import "../../styles/password-recovery.css";
import { Spinner } from "../../methods/spinner";

function PasswordRecovery() {
  const { setAlert, setAlertName, setAlertBorder } = useContext(GlobalContext);
  const [paramValue, setParamValue] = useState({});

  useEffect(() => {
    // Получаем параметр из URL
    const params = new URLSearchParams(window.location.search);
    const paramStatus = params.get("status");
    const paramToken = params.get("token");

    const paramArray = {
      status: paramStatus,
      token: paramToken,
    };

    // Обновляем состояние с параметром
    setParamValue(paramArray);
  }, []); // Эффект будет запускаться только один раз при монтировании компонента

  if (paramValue.status === "false") {
    const invalidToken = document.getElementById("invalidToken");
    invalidToken.classList.remove("hidden");
  }

  const sendRecoveryMessage = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target.closest("#passwordRecoveryForm"));
    const email = formData.get("email");
    const btn = new Spinner(
      document.querySelector("#passwordRecoveryForm button")
    );
    btn.start();

    try {
      await Api.sendRecoveryMessage(email);

      const invalidToken = document.getElementById("messageSuccessfullySent");
      invalidToken.classList.remove("hidden");
      const passwordRecovery = document.getElementById("passwordRecovery");
      passwordRecovery.classList.add("hidden");
    } catch (err) {
      setAlert(true);
      setAlertName("Email не существует");
      setAlertBorder("negative");
    }
    btn.end();
  };

  const passwordRecovery = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target.closest("#passwordChangeForm"));
    const password = formData.get("password");

    const btn = new Spinner(
      document.querySelector("#newPassword button")
    );
    btn.start();
    try {
      await Api.passwordRecovery(password, paramValue.token);

      const passwordSuccessfullyChange = document.getElementById(
        "passwordSuccessfullyChange"
      );
      passwordSuccessfullyChange.classList.remove("hidden");
      const newPassword = document.getElementById("newPassword");
      newPassword.classList.add("hidden");
    } catch (err) {
      if (err.response.status === 400) {
        const invalidToken = document.getElementById("invalidToken");
        invalidToken.classList.remove("hidden");
        const newPassword = document.getElementById("newPassword");
        newPassword.classList.add("hidden");
      }
    }
    btn.end();
  };

  return (
    <div className="password-recovery login">
      <ScrollToTop />

      {/* Восстановление пароля */}
      {!paramValue.token && paramValue.status !== "false" && (
        <div className="component" id="passwordRecovery">
          <h2>Восстановление пароля</h2>
          <form method="POST" id="passwordRecoveryForm">
            <input
              type="email"
              name="email"
              placeholder="Введите почту"
              required
            />
            <button
              className="button-default"
              type="submit"
              onClick={sendRecoveryMessage}
            >
              Отправить письмо на почту
            </button>
          </form>
          <div className="error"></div>
        </div>
      )}

      {/* Введите новый пароль */}
      {paramValue.token !== null && paramValue.status !== "false" && (
        <div className="component" id="newPassword">
          <h2>Введите новый пароль</h2>
          <form method="POST" id="passwordChangeForm">
            <input
              type="password"
              name="password"
              placeholder="Пароль"
              required
            />
            <input
              type="password"
              name="passwordRepeat"
              placeholder="Повтор пароля"
              required
            />
            <button
              className="button-default"
              type="submit"
              onClick={passwordRecovery}
            >
              Сохранить
            </button>
          </form>
          <div className="error"></div>
        </div>
      )}

      <div className="component hidden" id="messageSuccessfullySent">
        <h2>Письмо отправлено</h2>
        <p>Письмо отправлено, проверьте почту</p>
        <p>(Возможно письмо попало в папку "Спам")</p>
        <Link to="/login">Войти</Link>
      </div>

      <div className="component hidden" id="passwordSuccessfullyChange">
        <h2>Успешно</h2>
        <p>Ваш пароль был изменен</p>
        <Link to="/login">Войти</Link>
      </div>

      <div className="component hidden" id="invalidToken">
        <h2>Ошибка</h2>
        <p>Недействительный токен</p>
        <Link to="/login">Войти</Link>
      </div>
    </div>
  );
}
export default PasswordRecovery;
