import { useContext } from "react";
import { GlobalContext } from "../../store/GlobalContext";
import { Api } from "../../methods/requests/Api";
import { Spinner } from "../../methods/spinner";

const HistoryPaymentTask = () => {
  const body = document.querySelector("body");
  const { modal,setModal, modalData,setAlert,setAlertName,setAlertBorder } =
    useContext(GlobalContext);

  const toggleModal = async (e) => {
    e.preventDefault();
    const btn = new Spinner(document.querySelector('.payment-row button'));
    btn.start()
    try {
      modalData.items.map(async (item) => await Api.createTask(item));
    } catch (err) {
      console.log(err);
    }
    btn.end()
    body.style.overflow = "auto";
    setModal(!modal);
    setAlert(true);
    setAlertName("Задача добавлена");
    setAlertBorder("positive");
  };

  const services = {
    1: ["x5", "payment-item__number_orange"],
    2: ["x10", "payment-item__number_green"],
    3: ["x20", "payment-item__number_violet"],
    4: ["x30", "payment-item__number_red"],
  };

  return (
    <div className="modal-content">
      <h1>Оплата задачи</h1>
      <div className="payment-list">
        {modalData &&
          modalData.items.map((item) => (
            <div className="payment-item" key={item.id}>
              <div className="payment-item__left">
                <p className="payment-text__second">ID: {item.avito_id}</p>
                <p
                  className={
                    services
                      ? "payment-item__number " + services[item.service_id][1]
                      : ""
                  }
                >
                  {services[item.service_id][0]}
                </p>
                <p className="payment-text__second">
                  Дата окончания: {item.finish_time}
                </p>
                <p className="payment-text__second">
                  Срок: {item.duration} дней
                </p>
              </div>
              <p>{item.total_price} ₽</p>
            </div>
          ))}
        <p className="payment-sum">Итог: {modalData.value_total} ₽</p>
        <p className="">Списано бонусов: {modalData.value_bonus} ₽</p>
      </div>
      <p className="payment-text__second">Вид оплаты - безналичный расчёт</p>
      <div className="payment-row">
        <h2>Стоимость продвижения: {modalData.value_balance} ₽ </h2>
        <button
          className="button-active button-width_auto"
          onClick={toggleModal}
        >
          Повторить
        </button>
      </div>
    </div>
  );
};

export default HistoryPaymentTask;
