import { useContext } from "react";
import "../styles/tokenAvito.css";
import { GlobalContext } from "../store/GlobalContext";
import { Api } from "../methods/requests/Api";
import { Spinner } from "../methods/spinner";

function TokenAvito() {
  const { data, setAlert, setAlertBorder, setAlertName } =
    useContext(GlobalContext);

  function toggleInfoToken(e) {
    e.preventDefault();
    const componentInfoToken = document.querySelector(
      ".account-token__container"
    );
    componentInfoToken.classList.toggle("hidden");
  }

  const sendTokensAvito = async (e) => {
    e.preventDefault();
    const dataArray = {};
    const formData = new FormData(e.target.closest(".account-token__form"));
    const client_id = formData.get("firstToken");
    const client_secret = formData.get("secondToken");

    const btn = new Spinner(
      document.querySelector(".account-token__form button")
    );
    btn.start();

    try {
      if(client_id && client_secret){
        dataArray.client_id = client_id;
        await Api.sendTokenAvitoClientId(dataArray);
  
        dataArray.client_secret = client_secret;
        await Api.sendTokenAvitoClientSecret(dataArray);
        setAlert(true);
        setAlertName("Данные успешно обновлены");
        setAlertBorder("positive");
      }else {
        setAlert(true);
        setAlertName("Неверные данные");
        setAlertBorder("negative");
      }
    } catch (error) {
      console.log(error);
    }
    btn.end();
  };

  const editTokensAvito = async (e) => {
    e.preventDefault();
    const dataArray = {};
    const form = e.target.closest(".account-token__edit");
    const formData = new FormData(e.target.closest(".account-token__edit"));
    dataArray.token = formData.get("token");
    dataArray.id = form.querySelector(".account-token__input").id;

    const btn = new Spinner(
      document.querySelector(".account-token__form button")
    );
    btn.start();

    try {
      await Api.editTokenAvito(dataArray);
      setAlert(true);
      setAlertName("Данные успешно обновлены");
      setAlertBorder("positive");
    } catch (error) {
      console.log(error);
    }
    btn.end();
  };

  return (
    <div className="account-token component">
      <h2>Токен Авито</h2>
      <svg
        onClick={toggleInfoToken}
        className="account-token__info_icon"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 16C8.9851 16.0013 9.96075 15.8079 10.8709 15.4309C11.781 15.0539 12.6076 14.5007 13.3033 13.8033C14.0007 13.1076 14.5539 12.281 14.9309 11.3709C15.3079 10.4607 15.5013 9.4851 15.5 8.5C15.5013 7.51491 15.3078 6.53927 14.9308 5.62917C14.5539 4.71906 14.0007 3.89242 13.3033 3.19676C12.6076 2.49927 11.781 1.94613 10.8709 1.56914C9.96075 1.19215 8.9851 0.99873 8 1.00001C7.01491 0.99875 6.03927 1.19218 5.12917 1.56917C4.21906 1.94616 3.39242 2.49928 2.69676 3.19676C1.99928 3.89242 1.44616 4.71906 1.06917 5.62917C0.692176 6.53927 0.49875 7.51491 0.500006 8.5C0.49873 9.4851 0.692146 10.4607 1.06914 11.3709C1.44613 12.281 1.99927 13.1076 2.69676 13.8033C3.39242 14.5007 4.21906 15.0539 5.12917 15.4308C6.03927 15.8078 7.01491 16.0013 8 16Z"
          stroke="#0992DA"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 3.625C8.24864 3.625 8.4871 3.72377 8.66291 3.89959C8.83873 4.0754 8.9375 4.31386 8.9375 4.5625C8.9375 4.81114 8.83873 5.0496 8.66291 5.22541C8.4871 5.40123 8.24864 5.5 8 5.5C7.75136 5.5 7.5129 5.40123 7.33709 5.22541C7.16127 5.0496 7.0625 4.81114 7.0625 4.5625C7.0625 4.31386 7.16127 4.0754 7.33709 3.89959C7.5129 3.72377 7.75136 3.625 8 3.625Z"
          fill="#0992DA"
        />
        <path
          d="M8.1875 12.25V7H7.4375M6.875 12.25H9.5"
          stroke="#0992DA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className="account-token__container hidden">
        <p className="account-token__info component ">
          Введите токен Авито чтобы получить более простой интерфейс создания
          задач, более качественное повышение эффективности за счёт анализа
          профиля
        </p>
      </div>
      {data.avito_token && data.avito_token != 0 ? (
        <div className="account-token__form">
          {data.avito_token?.map((item) => (
            <form className="account-token__edit" key={item.id}>
              <input
                className="account-token__input"
                defaultValue={item.token}
                name="token"
                id={item.id}
                placeholder="Токен Авито"
              />
              <button
                className="button-default button-square"
                onClick={editTokensAvito}
              >
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7935 4.04006C18.1835 3.65006 18.1835 3.00006 17.7935 2.63006L15.4535 0.290059C15.0835 -0.0999414 14.4335 -0.0999414 14.0435 0.290059L12.2035 2.12006L15.9535 5.87006M0.0834961 14.2501V18.0001H3.8335L14.8935 6.93006L11.1435 3.18006L0.0834961 14.2501Z"
                    fill="black"
                  />
                </svg>
              </button>
            </form>
          ))}
        </div>
      ) : (
        <form className="account-token__form">
          <input defaultValue="" name="firstToken" placeholder="client_id" />
          <input
            defaultValue=""
            name="secondToken"
            placeholder="client_secret"
          />
          <button className="button-default" onClick={sendTokensAvito}>
            Добавить
          </button>
        </form>
      )}
    </div>
  );
}

export default TokenAvito;
