import { useContext, useEffect } from "react";
import { GlobalContext } from "../../store/GlobalContext";
import { getCookie } from "../../methods/getCookie";
import "../../styles/alerts/alert.css";
import { Link } from "react-router-dom";
import { ApiAdmin } from "../screens/admin/methods/requests/ApiAdmin";
import { Api } from "../../methods/requests/Api";
import { Spinner } from "../../methods/spinner";

const Alert = () => {
  const {
    alert,
    setAlert,
    alertName,
    alertData,
    alertBorder,
    setAlertName,
    setAlertBorder,
    setAllBasket,
  } = useContext(GlobalContext);

  const alertDataArray = Object.values(alertData);

  const closeAlert = () => {
    setAlert(false);
  };

  function exitAccount() {
    document.cookie = "currentToken=" + getCookie("currentToken") + "max-age=0";
    window.location.reload();
  }

  useEffect(() => {
    setTimeout(closeAlert, 13000);
  }, [alert]);

  // Удаление пользователя
  const deleteUser = async (idUser) => {
    try {
      await ApiAdmin.deleteUser(idUser);
      setAlert(true);
      setAlertName("Пользователь удалён");
      setAlertBorder("positive");
    } catch (error) {
      console.log(error);
    }
  };

  // Удаление роли
  const deleteRole = async (idRole) => {
    try {
      await ApiAdmin.deleteRole(idRole);
      setAlert(true);
      setAlertName("Роль удалена");
      setAlertBorder("positive");
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTask = async () => {
    const btn = new Spinner(document.querySelector(".alert .button-red"));
    btn.start();
    try {
      await Api.deleteTaskBasket(alertData);
      const response = await Api.getBasket();
      setAllBasket(response.data.data);
      setAlert(false);
    } catch (error) {
      console.log(error);
    }
    btn.end();
  };

  return (
    <div
      className={
        alert
          ? alert &&
            "alert component alert-active" &&
            (alertBorder === "positive"
              ? "alert component alert-active alert-positive"
              : "alert component alert-active")
          : "alert component"
      }
    >
      <div className="alert-close" onClick={closeAlert}>
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.4503 1.065C19.3116 0.925945 19.1467 0.815623 18.9653 0.740351C18.7838 0.665079 18.5893 0.626333 18.3928 0.626333C18.1964 0.626333 18.0019 0.665079 17.8204 0.740351C17.6389 0.815623 17.4741 0.925945 17.3353 1.065L10.0003 8.385L2.66534 1.05C2.52646 0.911127 2.3616 0.800967 2.18015 0.725809C1.9987 0.650652 1.80423 0.611969 1.60784 0.611969C1.41144 0.611969 1.21697 0.650652 1.03552 0.725809C0.854074 0.800967 0.689208 0.911127 0.550335 1.05C0.411462 1.18887 0.301302 1.35374 0.226145 1.53518C0.150987 1.71663 0.112305 1.9111 0.112305 2.1075C0.112305 2.3039 0.150987 2.49837 0.226145 2.67981C0.301302 2.86126 0.411462 3.02613 0.550335 3.165L7.88534 10.5L0.550335 17.835C0.411462 17.9739 0.301302 18.1387 0.226145 18.3202C0.150987 18.5016 0.112305 18.6961 0.112305 18.8925C0.112305 19.0889 0.150987 19.2834 0.226145 19.4648C0.301302 19.6463 0.411462 19.8111 0.550335 19.95C0.689208 20.0889 0.854074 20.199 1.03552 20.2742C1.21697 20.3493 1.41144 20.388 1.60784 20.388C1.80423 20.388 1.9987 20.3493 2.18015 20.2742C2.3616 20.199 2.52646 20.0889 2.66534 19.95L10.0003 12.615L17.3353 19.95C17.4742 20.0889 17.6391 20.199 17.8205 20.2742C18.002 20.3493 18.1964 20.388 18.3928 20.388C18.5892 20.388 18.7837 20.3493 18.9651 20.2742C19.1466 20.199 19.3115 20.0889 19.4503 19.95C19.5892 19.8111 19.6994 19.6463 19.7745 19.4648C19.8497 19.2834 19.8884 19.0889 19.8884 18.8925C19.8884 18.6961 19.8497 18.5016 19.7745 18.3202C19.6994 18.1387 19.5892 17.9739 19.4503 17.835L12.1153 10.5L19.4503 3.165C20.0203 2.595 20.0203 1.635 19.4503 1.065Z"
            fill="black"
          />
        </svg>
      </div>
      {alertName === "Выход из аккаунта" && (
        <div className="alert-content">
          <h1>Важно!</h1>
          <p>Вы уверены, что хотите выйти?</p>
          <div className="alert-content__buttons">
            <button className="button-default" onClick={closeAlert}>
              Отмена
            </button>
            <button className="button-red" onClick={exitAccount}>
              Выйти
            </button>
          </div>
        </div>
      )}

      {alertName === "Подтверждение удаления задачи" && (
        <div className="alert-content">
          <h1>Важно!</h1>
          <p>Вы уверены, что хотите удалить задачу?</p>
          <div className="alert-content__buttons">
            <button className="button-default" onClick={closeAlert}>
              Отмена
            </button>
            <button className="button-red" onClick={deleteTask}>
              Удалить
            </button>
          </div>
        </div>
      )}

      {alertName === "Неверный пароль" && (
        <div className="alert-content">
          <h1>Ошибка</h1>
          <p>Неверный логин или пароль</p>
        </div>
      )}

      {alertName === "Email не существует" && (
        <div className="alert-content">
          <h1>Ошибка</h1>
          <p>Пользователя с данным email не существует</p>
          <Link to="/registration">Регистрация</Link>
        </div>
      )}

      {alertName === "Неверные данные" && (
        <div className="alert-content">
          <h1>Ошибка</h1>
          <p>Введены неверные данные</p>
        </div>
      )}

      {alertName === "Проблемы с сервером" && (
        <div className="alert-content">
          <h1>Внимание</h1>
          <p>Проблемы с сервером, повторите попытку позже</p>
        </div>
      )}

      {alertName === "Недостаточно средств" && (
        <div className="alert-content error-validation">
          <h1>Недостаточно средств</h1>
          <p>У Вас недостаточно средств для оплаты задачи</p>
        </div>
      )}

      {alertName === "Ошибка валидации" && (
        <div className="alert-content error-validation">
          <h1>Ошибка валидации</h1>
          {alertDataArray?.map((item) => (
            <p>{item}</p>
          ))}
        </div>
      )}

      {alertName === "Подтвердите аккаунт" && (
        <div className="alert-content">
          <h1>Подтвердите аккаунт</h1>
          <p>Мы выслали письмо для подтверждения аккаунта на вашу почту</p>
        </div>
      )}

      {alertName === "Согласие с политикой" && (
        <div className="alert-content">
          <h1>Ошибка</h1>
          <p>Вы не согласились с политикой конфиденциальности</p>
        </div>
      )}

      {alertName === "Данные успешно обновлены" && (
        <div className="alert-content">
          <h1>Данные обновлены</h1>
          <p>Данные успешно обновлены</p>
        </div>
      )}

      {alertName === "Пароль изменен" && (
        <div className="alert-content">
          <h1>Пароль изменен</h1>
          <p>Пароль успешно изменен</p>
        </div>
      )}

      {alertName === "Текст скопирован" && (
        <div className="alert-content">
          <h1>Текст скопирован</h1>
          <p>Текст скопирован в буфер обмена</p>
        </div>
      )}

      {alertName === "Оплата прошла" && (
        <div className="alert-content">
          <h1>Оплата прошла</h1>
          <p>Оплата прошла успешно</p>
        </div>
      )}

      {alertName === "Задача добавлена" && (
        <div className="alert-content">
          <h1>Задача добавлена</h1>
          <p>Задача успешно добавлена</p>
        </div>
      )}

      {alertName === "Задача удалена" && (
        <div className="alert-content">
          <h1>Задача удалена</h1>
          <p>Задача успешно удалена</p>
        </div>
      )}

      {alertName === "Письмо отправлено" && (
        <div className="alert-content">
          <h1>Письмо отправлено</h1>
          <p>Письмо отправлено, проверьте почту</p>
          <p>(Возможно письмо попало в папку "Спам")</p>
        </div>
      )}

      {alertName === "Incorrect url" && (
        <div className="alert-content">
          <h1>Некорректная ссылка</h1>
          <p>Введите корректную ссылку</p>
        </div>
      )}

      {/* Админ */}
      {alertName === "Пользователь удалён" && (
        <div className="alert-content">
          <h1>Успешно</h1>
          <p>Пользователь удалён</p>
        </div>
      )}

      {alertName === "Роль удалена" && (
        <div className="alert-content">
          <h1>Успешно</h1>
          <p>Роль удалена</p>
        </div>
      )}

      {alertName === "Удаление пользователя" && (
        <div className="alert-content">
          <h1>Важно!</h1>
          <p>Вы уверены, что хотите удалить пользователя с id {alertData}?</p>
          <div className="alert-content__buttons">
            <button className="button-default" onClick={closeAlert}>
              Отмена
            </button>
            <button
              className="button-red"
              onClick={() => deleteUser(alertData)}
            >
              Удалить
            </button>
          </div>
        </div>
      )}

      {alertName === "Удаление роли" && (
        <div className="alert-content">
          <h1>Важно!</h1>
          <p>Вы уверены, что хотите удалить роль с id {alertData}?</p>
          <div className="alert-content__buttons">
            <button className="button-default" onClick={closeAlert}>
              Отмена
            </button>
            <button
              className="button-red"
              onClick={() => deleteRole(alertData)}
            >
              Удалить
            </button>
          </div>
        </div>
      )}

      {alertName === "Добавление роли" && (
        <div className="alert-content">
          <h1>Успешно</h1>
          <p>Роль добавлена</p>
        </div>
      )}
    </div>
  );
};

export default Alert;
