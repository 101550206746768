import "../../../styles/landing/advantages.css";

function Advantages() {
  return (
    <div className="component advantages" id="advantages">
      <div className="landing-title">
        <h3>Говорящие цифры</h3>
        <h2>Проверено на деле</h2>
        <p>
          Это средние показатели наших клиентов после месяца использование
          платформы
        </p>
      </div>
      <div className="advantages-list">
        <div className="advantages-list__item">
          <p className="advantages-item__title">+350%</p>
          <p className="advantages-item__text">Просмотров</p>
        </div>
        <div className="advantages-list__item">
          <p className="advantages-item__title">+230%</p>
          <p className="advantages-item__text">Контактов</p>
        </div>
        <div className="advantages-list__item">
          <p className="advantages-item__title">55%</p>
          <p className="advantages-item__text">Экономии</p>
        </div>
        <div className="advantages-list__item">
          <p className="advantages-item__title">+100%</p>
          <p className="advantages-item__text">Выручки</p>
        </div>
      </div>
    </div>
  );
}
export default Advantages;
