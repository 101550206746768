import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import ScrollToTop from "../../methods/scrollToTop";

function VerifyEmail() {
  const [paramValue, setParamValue] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Получаем параметр из URL
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get("status");

    // Обновляем состояние с параметром
    setParamValue(paramValue);
    if(!(paramValue)){
      return navigate("/login")
    }
  }, []); // Эффект будет запускаться только один раз при монтировании компонента

  return (
    <div className="verify-email login">
      <ScrollToTop/>
      {paramValue === "true" && (
        <div className="component">
          <h2>Успешно</h2>
          <p>Ваш аккаунт подтвержден</p>
          <Link to="/login">Войти</Link>
        </div>
      )}
      {paramValue === "false" && (
        <div className="component">
          <h2>Ошибка</h2>
          <p>Недействительный токен</p>
          <Link to="/login">Войти</Link>
        </div>
      )}
    </div>
  );
}

export default VerifyEmail;
