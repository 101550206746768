import { NavLink } from "react-router-dom";
import "../styles/navMenu.css";

function NavMenu() {
  return (
    <nav className="nav-menu">
      <ul>
        <NavLink
          id="navHome"
          to="/home"
          className="nav-menu-link"
          activeclassname={"nav-menu-li_active"}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7066 0.293031C11.5191 0.105559 11.2648 0.000244141 10.9996 0.000244141C10.7344 0.000244141 10.4801 0.105559 10.2926 0.293031L3.29259 7.29303L1.29259 9.29303C1.19708 9.38528 1.1209 9.49562 1.06849 9.61763C1.01608 9.73963 0.988496 9.87085 0.987342 10.0036C0.986189 10.1364 1.01149 10.2681 1.06177 10.391C1.11205 10.5139 1.18631 10.6255 1.2802 10.7194C1.37409 10.8133 1.48574 10.8876 1.60864 10.9379C1.73154 10.9881 1.86321 11.0134 1.99599 11.0123C2.12877 11.0111 2.25999 10.9835 2.382 10.9311C2.504 10.8787 2.61435 10.8025 2.70659 10.707L2.99959 10.414V17C2.99959 17.7957 3.31566 18.5587 3.87827 19.1214C4.44088 19.684 5.20394 20 5.99959 20H15.9996C16.7952 20 17.5583 19.684 18.1209 19.1214C18.6835 18.5587 18.9996 17.7957 18.9996 17V10.414L19.2926 10.707C19.4812 10.8892 19.7338 10.99 19.996 10.9877C20.2582 10.9854 20.509 10.8803 20.6944 10.6948C20.8798 10.5094 20.985 10.2586 20.9873 9.99643C20.9895 9.73423 20.8888 9.48163 20.7066 9.29303L11.7066 0.293031Z"
              fill="#0992DA"
            />
          </svg>
          <p>Главная</p>
        </NavLink>
        <NavLink
          id="navTasks"
          to="/my-tasks"
          className="nav-menu-link"
          activeclassname={"nav-menu-li_active"}
        >
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="26" height="25" rx="3.90625" fill="#0B99FF" />
            <path
              d="M4.91108 8.47005C3.86921 8.47005 3 7.62292 3 6.57878C3 5.53464 3.87 4.6875 4.91108 4.6875C5.41178 4.6875 5.89196 4.88676 6.24601 5.24144C6.60006 5.59613 6.79896 6.07718 6.79896 6.57878C6.79896 7.08037 6.60006 7.56143 6.24601 7.91611C5.89196 8.27079 5.41178 8.47005 4.91108 8.47005ZM21.902 7.83963H10.5747C10.4078 7.83963 10.2477 7.77321 10.1297 7.65498C10.0117 7.53675 9.94542 7.3764 9.94542 7.2092V5.94835C9.94542 5.78115 10.0117 5.6208 10.1297 5.50257C10.2477 5.38434 10.4078 5.31793 10.5747 5.31793H21.902C22.0689 5.31793 22.2289 5.38434 22.3469 5.50257C22.465 5.6208 22.5312 5.78115 22.5312 5.94835V7.2092C22.5312 7.3764 22.465 7.53675 22.3469 7.65498C22.2289 7.77321 22.0689 7.83963 21.902 7.83963ZM21.902 20.4481H10.5747C10.4078 20.4481 10.2477 20.3817 10.1297 20.2635C10.0117 20.1453 9.94542 19.9849 9.94542 19.8177V18.5569C9.94542 18.3897 10.0117 18.2293 10.1297 18.1111C10.2477 17.9929 10.4078 17.9264 10.5747 17.9264H21.902C22.0689 17.9264 22.2289 17.9929 22.3469 18.1111C22.465 18.2293 22.5312 18.3897 22.5312 18.5569V19.8177C22.5312 19.9849 22.465 20.1453 22.3469 20.2635C22.2289 20.3817 22.0689 20.4481 21.902 20.4481ZM21.902 14.1439H10.5747C10.4078 14.1439 10.2477 14.0775 10.1297 13.9592C10.0117 13.841 9.94542 13.6807 9.94542 13.5135V12.2526C9.94542 12.0854 10.0117 11.9251 10.1297 11.8068C10.2477 11.6886 10.4078 11.6222 10.5747 11.6222H21.902C22.0689 11.6222 22.2289 11.6886 22.3469 11.8068C22.465 11.9251 22.5312 12.0854 22.5312 12.2526V13.5135C22.5312 13.6807 22.465 13.841 22.3469 13.9592C22.2289 14.0775 22.0689 14.1439 21.902 14.1439Z"
              fill="white"
            />
            <path
              d="M4.91108 21.0938C3.86921 21.0938 3 20.2466 3 19.2025C3 18.1583 3.87 17.3112 4.91108 17.3112C5.41178 17.3112 5.89196 17.5105 6.24601 17.8651C6.60006 18.2198 6.79896 18.7009 6.79896 19.2025C6.79896 19.7041 6.60006 20.1851 6.24601 20.5398C5.89196 20.8945 5.41178 21.0937 4.91108 21.0938Z"
              fill="white"
            />
            <path
              d="M4.91108 14.8953C3.86921 14.8953 3 14.0482 3 13.004C3 11.9599 3.87 11.1128 4.91108 11.1128C5.41178 11.1128 5.89196 11.312 6.24601 11.6667C6.60006 12.0214 6.79896 12.5024 6.79896 13.004C6.79896 13.5056 6.60006 13.9867 6.24601 14.3414C5.89196 14.696 5.41178 14.8953 4.91108 14.8953Z"
              fill="white"
            />
          </svg>
          <p>Мои задачи</p>
        </NavLink>
        <NavLink
          id="createTasks"
          to="/create-task"
          className="nav-menu-link"
          activeclassname={"nav-menu-li_active"}
        >
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="19.0003" cy="18.9583" r="18.9583" fill="#0B99FF" />
            <path
              d="M28.8921 20.6069H20.6493V28.8496C20.6493 29.2868 20.4757 29.7062 20.1665 30.0153C19.8573 30.3245 19.438 30.4982 19.0008 30.4982C18.5636 30.4982 18.1443 30.3245 17.8351 30.0153C17.5259 29.7062 17.3522 29.2868 17.3522 28.8496V20.6069H9.10949C8.67227 20.6069 8.25295 20.4332 7.94379 20.124C7.63462 19.8148 7.46094 19.3955 7.46094 18.9583C7.46094 18.5211 7.63462 18.1018 7.94379 17.7926C8.25295 17.4834 8.67227 17.3098 9.10949 17.3098H17.3522V9.06701C17.3522 8.62978 17.5259 8.21047 17.8351 7.90131C18.1443 7.59214 18.5636 7.41846 19.0008 7.41846C19.438 7.41846 19.8573 7.59214 20.1665 7.90131C20.4757 8.21047 20.6493 8.62978 20.6493 9.06701V17.3098H28.8921C29.3293 17.3098 29.7486 17.4834 30.0578 17.7926C30.367 18.1018 30.5406 18.5211 30.5406 18.9583C30.5406 19.3955 30.367 19.8148 30.0578 20.124C29.7486 20.4332 29.3293 20.6069 28.8921 20.6069Z"
              fill="white"
            />
          </svg>
          <p>Создать задачу</p>
        </NavLink>
        <NavLink
          id="account"
          to="/account"
          className="nav-menu-link"
          activeclassname={"nav-menu-li_active"}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 0C5.3724 0 0 5.3724 0 12C0 18.6276 5.3724 24 12 24C18.6276 24 24 18.6276 24 12C24 5.3724 18.6276 0 12 0ZM7.8 9C7.8 8.44845 7.90864 7.9023 8.11971 7.39273C8.33078 6.88316 8.64015 6.42016 9.03015 6.03015C9.42016 5.64015 9.88316 5.33078 10.3927 5.11971C10.9023 4.90864 11.4484 4.8 12 4.8C12.5516 4.8 13.0977 4.90864 13.6073 5.11971C14.1168 5.33078 14.5798 5.64015 14.9698 6.03015C15.3599 6.42016 15.6692 6.88316 15.8803 7.39273C16.0914 7.9023 16.2 8.44845 16.2 9C16.2 10.1139 15.7575 11.1822 14.9698 11.9698C14.1822 12.7575 13.1139 13.2 12 13.2C10.8861 13.2 9.81781 12.7575 9.03015 11.9698C8.2425 11.1822 7.8 10.1139 7.8 9ZM19.5096 17.9808C18.6113 19.1107 17.4694 20.0231 16.169 20.6498C14.8686 21.2765 13.4435 21.6013 12 21.6C10.5565 21.6013 9.13136 21.2765 7.831 20.6498C6.53064 20.0231 5.38868 19.1107 4.4904 17.9808C6.4356 16.5852 9.09 15.6 12 15.6C14.91 15.6 17.5644 16.5852 19.5096 17.9808Z"
              fill="#0B99FF"
            />
          </svg>
          <p>Профиль</p>
        </NavLink>
      </ul>
    </nav>
  );
}

export default NavMenu;
