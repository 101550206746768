import "../../../styles/landing/intro.css";

import introPhoto from "../../../images/mockup.png";
import { Link } from "react-router-dom";

function Intro() {
  return (
    <div className="component intro" id="intro">
      {/* <div className="intro-left"> */}
      <h1>Платформа для продвижения объявлений на Авито</h1>
      <p>
        Новые возможности позволяют умножить результаты продвижения и
        расположиться на главной странице рекомендаций.
      </p>
      <div className="intro-buttons">
        <Link to="/registration">
          <button className="button-default button-active">Начать бесплатно</button>
        </Link>
        <a href="#">Написать в Telegram</a>
      </div>
      {/* </div> */}
      <img alt="Фото баннера" src={introPhoto} />
    </div>
  );
}
export default Intro;
