import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../store/GlobalContext";
import { Api } from "../../methods/requests/Api";

const CartTasksTable = ({ title }) => {
  const {
    modal,
    setModal,
    setModalName,
    setCurrentTask,
    setSpiner,
    spiner,
    setAlert,
    setAlertName,
    setAlertBorder,
    setAlertData,
    allBasket,
    setAllBasket,
  } = useContext(GlobalContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const body = document.querySelector("body");

  const getBasket = async (page = 1) => {
    if (allBasket) {
      setSpiner(true);
      try {
        const response = await Api.getBasket(page);
        setAllBasket(response.data.data);

        setTotalPages(response.data.pagination.totalPage);
        setSpiner(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const toggleModal = (modalName, dataCartTask) => {
    setCurrentTask(dataCartTask);
    setModal(!modal);
    setModalName(modalName);
    if (modal === false) {
      body.style.overflow = "hidden";
    }
  };

  const deleteTaskBasket = async (taskId) => {
    setAlert(true);
    setAlertName("Подтверждение удаления задачи");
    setAlertBorder("negative");
    setAlertData(taskId);
  };

  const services = {
    1: ["x5", "payment-item__number_orange"],
    2: ["x10", "payment-item__number_green"],
    3: ["x20", "payment-item__number_violet"],
    4: ["x30", "payment-item__number_red"],
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getBasket(page); // Запросите данные для новой страницы
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={
            currentPage === i
              ? "pagination-item pagination-item_active"
              : "pagination-item"
          }
        >
          {i}
        </button>
      );
    }
    return <div className="pagination">{pages}</div>;
  };

  useEffect(() => {
    getBasket(currentPage);
  }, [currentPage]);

  return (
    <div className="table-products__content">
      {spiner ? (
        <div className="skeleton-table__list">
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
          <div className="skeleton-table-item">
            <div className="skeleton-table__left">
              <div className="block pulsate"></div>
              <div className="skeleton-table__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="skeleton-table__buttons">
              <div className="block pulsate"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="table-products__list">
          {allBasket ? (
            allBasket &&
            allBasket.map((item) => (
              <div className="table-item" key={item.id}>
                <div className="table-item__left">
                  <div className="table-item__left">
                    {/* <img
                    className="table-item__img"
                    src={item.image}
                    alt="Фото объявления"
                  /> */}
                    <div className="table-text__container">
                      <a href={item.url}>
                        <h3 className="table-item__title">{item.title}</h3>
                      </a>
                      <div className="table-text__description">
                        <p className="table-item__id">ID: {item.avito_id}</p>
                        <p className="table-item__location">{item.address}</p>
                        <p
                          className={
                            services
                              ? "payment-item__number " +
                                services[item.service_id][1]
                              : ""
                          }
                        >
                          {services[item.service_id][0]}
                        </p>
                        <p className="table-item__price">
                          Стоимость услуги: {item.total_price} ₽
                        </p>
                        <p className="table-item__date">
                          Дата окончания: {item.finish_time}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-item__buttons">
                  <button
                    onClick={() => toggleModal(title, item)}
                    className="button-default"
                  >
                    Выбрать
                  </button>
                  <button
                    className="button-red button-square"
                    onClick={() => deleteTaskBasket(item.id)}
                  >
                    <svg
                      width="14"
                      height="19"
                      viewBox="0 0 14 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 1.5H10.5L9.5 0.5H4.5L3.5 1.5H0V3.5H14M1 16.5C1 17.0304 1.21071 17.5391 1.58579 17.9142C1.96086 18.2893 2.46957 18.5 3 18.5H11C11.5304 18.5 12.0391 18.2893 12.4142 17.9142C12.7893 17.5391 13 17.0304 13 16.5V4.5H1V16.5Z"
                        fill="#F34343"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <h1>Здесь пока ничего нет {":("}</h1>
          )}
        </div>
      )}
      {totalPages > 1 ? renderPagination() : ""}
    </div>
  );
};

export default CartTasksTable;
