import {useContext, useEffect, useState} from "react";
import { GlobalContext } from "../store/GlobalContext";
import { Api } from "../methods/requests/Api";
import { Spinner } from "../methods/spinner";

function EditPersonalInfo() {
  const {
    data,
    modal,
    setModal,
    setModalName,
    setAlert,
    setAlertBorder,
    setAlertData,
    setAlertName,
  } = useContext(GlobalContext);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [selectMessenger, setSelectMessenger] = useState('telegram');

  const phoneHandler = (event) => {
    setPhone(event.target.value);
  };

  const emailHandler = (event) => {
    setEmail(event.target.value);
  };

  function optionValue(e) {
    setSelectMessenger(e.target.value);
    const select = document.querySelector("#messenger");
    const input = document.querySelector(".messenger");
    input.setAttribute("name", select.value);
  }

  const body = document.querySelector("body");

  function toggleEditProfile(e) {
    e.preventDefault();
    const componentEditProfile = document.querySelector(".edit");
    componentEditProfile.classList.toggle("hidden");
  }

  //   Модальное окно смены пароля
  function toggleModal(e) {
    const modalName = "Смена пароля";
    e.preventDefault();
    setModal(!modal);
    setModalName(modalName);

    if (modal === false) {
      body.style.overflow = "hidden";
    }
  }

  const editInfo = async (e) => {
    e.preventDefault();

    const dataArray = {};

    const formData = new FormData(e.target.closest(".edit-form"));

    if (formData.get("phone") !== data.phone) {
      dataArray.phone = formData.get("phone");
    }
    if (formData.get("email") !== data.email) {
      dataArray.email = formData.get("email");
    }

    const input = document.querySelector(".messenger");
    if (input.name === "telegram" && input.value !== data.telegram) {
      dataArray.telegram = input.value;
    }

    if (input.name === "whatsapp" && input.value !== data.whatsapp) {
      dataArray.whatsapp = input.value;
    }

    const btn = new Spinner(
      document.querySelector("#edit #editSave")
    );
    btn.start();

    try {
      await Api.editPersonalInfo(dataArray, data.id);
      setAlert(true);
      setAlertName("Данные успешно обновлены");
      setAlertBorder("positive");
    } catch (err) {
      const errorMessage = err.response.data.message;
      setAlert(true);
      setAlertData(err.response.data.data);
      setAlertName(errorMessage);
      setAlertBorder("negative");
    }
    btn.end();
  };

  useEffect(() => {
    if (data.telegram != null) setSelectMessenger('telegram')
    else setSelectMessenger('whatsapp');
  }, [data.telegram]);

  return (
    <div className="edit component hidden" id="edit">
      <h2>Редактирование</h2>
      <form className="edit-form">
        <div className="edit-labels">
          <label>
            <span className="span-row">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_15_68)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 16C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.316071 14.5587 0 13.7956 0 13V3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H17C17.7956 0 18.5587 0.316071 19.1213 0.87868C19.6839 1.44129 20 2.20435 20 3V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H3ZM5.625 4.22C5.52313 4.13302 5.40483 4.06739 5.27711 4.027C5.1494 3.98662 5.01487 3.97231 4.88151 3.98491C4.74816 3.99752 4.61869 4.03679 4.50081 4.1004C4.38292 4.164 4.27901 4.25064 4.19525 4.35517C4.11149 4.4597 4.04959 4.58 4.01321 4.70891C3.97684 4.83783 3.96673 4.97274 3.9835 5.10564C4.00026 5.23853 4.04356 5.36671 4.11081 5.48255C4.17806 5.59839 4.2679 5.69954 4.375 5.78L8.125 8.781C8.65709 9.20701 9.31838 9.43913 10 9.43913C10.6816 9.43913 11.3429 9.20701 11.875 8.781L15.625 5.781C15.7276 5.69892 15.813 5.59745 15.8763 5.48237C15.9396 5.3673 15.9797 5.24087 15.9942 5.11031C16.0087 4.97975 15.9973 4.84762 15.9607 4.72145C15.9241 4.59529 15.8631 4.47756 15.781 4.375C15.6989 4.27244 15.5974 4.18705 15.4824 4.1237C15.3673 4.06035 15.2409 4.02029 15.1103 4.00581C14.9798 3.99132 14.8476 4.00269 14.7215 4.03927C14.5953 4.07585 14.4776 4.13692 14.375 4.219L10.625 7.219C10.4476 7.361 10.2272 7.43838 10 7.43838C9.77279 7.43838 9.55236 7.361 9.375 7.219L5.625 4.22Z"
                    fill="#0B99FF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_15_68">
                    <rect width="20" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Email</p>
            </span>
            <input
              name="email"
              defaultValue={data.email}
              onChange={emailHandler}
            />
          </label>
          <label>
            <span className="span-row">
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.556 10.906L12.101 11.359C12.101 11.359 11.018 12.435 8.063 9.49698C5.108 6.55898 6.191 5.48298 6.191 5.48298L6.477 5.19698C7.184 4.49498 7.251 3.36698 6.634 2.54298L5.374 0.859979C4.61 -0.160021 3.135 -0.29502 2.26 0.57498L0.690002 2.13498C0.257002 2.56698 -0.0329985 3.12498 0.00200151 3.74498C0.0920015 5.33198 0.810001 8.74498 4.814 12.727C9.061 16.949 13.046 17.117 14.675 16.965C15.191 16.917 15.639 16.655 16 16.295L17.42 14.883C18.38 13.93 18.11 12.295 16.882 11.628L14.972 10.589C14.166 10.152 13.186 10.28 12.556 10.906Z"
                  fill="#0B99FF"
                />
              </svg>
              <p>Телефон</p>
            </span>
            <input
              name="phone"
              defaultValue={data.phone}
              onClick={phoneHandler}
            />
          </label>
          <label>
            <span className="span-row_nogap">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.09375 0.0196866C8.87054 0.14996 9.39732 0.891171 9.26786 1.67281L8.83036 4.31422H13.0759L13.5893 1.20113C13.7188 0.419491 14.4554 -0.110587 15.2321 0.0196866C16.0089 0.14996 16.5357 0.891171 16.4063 1.67281L15.9732 4.31422H18.5714C19.3616 4.31422 20 4.9566 20 5.75172C20 6.54684 19.3616 7.18922 18.5714 7.18922H15.4955L14.5446 12.9392H17.1429C17.933 12.9392 18.5714 13.5816 18.5714 14.3767C18.5714 15.1718 17.933 15.8142 17.1429 15.8142H14.067L13.5536 18.9273C13.4241 19.7089 12.6875 20.239 11.9107 20.1088C11.1339 19.9785 10.6071 19.2373 10.7366 18.4556L11.1741 15.8187H6.92411L6.41071 18.9318C6.28125 19.7134 5.54464 20.2435 4.76786 20.1132C3.99107 19.983 3.46429 19.2418 3.59375 18.4601L4.02679 15.8142H1.42857C0.638393 15.8142 0 15.1718 0 14.3767C0 13.5816 0.638393 12.9392 1.42857 12.9392H4.50446L5.45536 7.18922H2.85714C2.06696 7.18922 1.42857 6.54684 1.42857 5.75172C1.42857 4.9566 2.06696 4.31422 2.85714 4.31422H5.93304L6.44643 1.20113C6.57589 0.419491 7.3125 -0.110587 8.08929 0.0196866H8.09375ZM8.35268 7.18922L7.40179 12.9392H11.6473L12.5982 7.18922H8.35268Z"
                  fill="#0B99FF"
                />
              </svg>
              <select id="messenger" value={selectMessenger} onChange={optionValue}>
                <option value="telegram">
                  Telegram тег*
                </option>
                <option value="whatsapp">
                  WhatsApp тег*
                </option>
              </select>
            </span>
            <input
              type="text"
              className="messenger"
              name={data.telegram !== null ? "telegram" : "whatsapp"}
              defaultValue={
                data.telegram !== null ? data.telegram : data.whatsapp
              }
            />
          </label>
        </div>

        <div className="edit-buttons">
          <button onClick={toggleEditProfile} className="button-red">
            Отменить
          </button>
          <button onClick={toggleModal} className="button-default">
            Сменить пароль
          </button>
          <button className="button-positive" onClick={editInfo} id="editSave">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditPersonalInfo;
