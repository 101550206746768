import "../../../styles/landing/reviews.css";

import woman1 from "../../../images/woman1.png";
import man1 from "../../../images/man1.png";
import man2 from "../../../images/man2.png";

function Reviews() {
  return (
    <div className="component reviews" id="reviews">
      <div className="landing-title">
        <h3>То что о нас говорят</h3>
        <h2>Добрые слова наших пользователей</h2>
      </div>
      <div className="reviews-list">
        <div className="reviews-list__item">
          <div className="review-text">
            <p>Большая благодарность!</p>
            <p>
              Открыли нам новые возможности. Сервис очень удобный, а главное
              реально ощутимый результат дает этот ваш буст. Теперь мы чаще в
              рекомендации выходим на главной странички Авито и продвижение
              стало вдвойне эффетивней
            </p>
          </div>
          <div className="review-person">
            <div className="img">
              <img src={woman1} />
            </div>
            <div className="review-person__text">
              <p>Маргарита Наумова</p>
              <div>
                <p className="review-person__text_small">директор компании</p>
                <p className="review-person__text_small">
                  ремонту квартир г. Москва
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="reviews-list__item">
          <div className="review-text">
            <p>Полезно, спасибо!</p>
            <p>
              Реально уже на четвертый день увидели результаты. Раньше
              продвижение Х10 давал плюс 40 просмотров, сейчас при тех же х10 у
              нас 120 просмотров в день стало. Теперь постоянно поддерживаем
              искусственный спрос, для роста продаж.
            </p>
          </div>
          <div className="review-person">
            <div className="img">
              <img src={man1} />
            </div>
            <div className="review-person__text">
              <p>Евгений Кузнецов</p>
              <div>
                <p className="review-person__text_small">авитолог-аналитик</p>
                <p className="review-person__text_small">
                  продажа электроники г. Краснодар
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="reviews-list__item">
          <div className="review-text">
            <p>Хороший сервис!</p>
            <p>
              Уже в первый месяц перевыполнили план продаж на 150%. Стали
              постоянными вашими пользователями. Спасибо и дальнейшего нам с
              вами развития!!!
            </p>
          </div>
          <div className="review-person">
            <div className="img">
              <img src={man2} />
            </div>
            <div className="review-person__text">
                <p>Сергей Иванов</p>
              <div>
                <p className="review-person__text_small">
                  руководитель отдела продаж
                </p>
                <p className="review-person__text_small">
                  аренда инструмента г. Самара
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="reviews-pagination">
        <div className="reviews-pagination__arrow reviews-pagination__back">
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.1775 1.44751L1.625 6.00001L6.1775 10.5525"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.375 6H1.7525"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="reviews-pagination__arrow reviews-pagination__next">
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.82251 1.44751L14.375 6.00001L9.82251 10.5525"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.625 6H14.2475"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div> */}
    </div>
  );
}
export default Reviews;
