import { Link } from "react-router-dom";
import "../../../styles/landing/start.css";

function Start() {
  return (
    <div className="component start" id="start">
      <div className="start-info">
        <div className="landing-title">
          <h3>Откройте новые возможности</h3>
          <h2>Начните эффективней продвигать свой бизнес</h2>
          <p>
            На платформе легко найдете инструменты для быстрого старта и
            развития вашего текущего продвижения. У нас в штате есть
            маркетологи, авитологи и аналитики, которые смогут
            проконсультировать. Стоимость услуги рассчитывается на платформе в
            зависимости от выбранных параметров. Предоставляем техническую
            поддержку 24/7
          </p>
        </div>
        <Link to="/registration">
          <button className="button-default button-active">
            Получить аккаунт
          </button>
        </Link>
      </div>
      <div className="start-photo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="512"
          height="512"
          x="0"
          y="0"
          viewBox="0 0 682.667 682.667"
        >
          <g>
            <defs>
              <clipPath id="a" clipPathUnits="userSpaceOnUse">
                <path
                  d="M0 512h512V0H0Z"
                  fill="#0b99ff"
                  opacity="1"
                  data-original="#000000"
                  className=""
                ></path>
              </clipPath>
            </defs>
            <path
              d="M0 0h212.437"
              transform="matrix(1.33333 0 0 -1.33333 271.413 108.22)"
              fill="none"
              stroke="#0b99ff"
              stroke-width="15"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-dasharray="none"
              stroke-opacity=""
              data-original="#000000"
              opacity="1"
              className=""
            ></path>
            <g
              clip-path="url(#a)"
              transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
            >
              <path
                d="M0 0h-35.39c-15.787 0-28.586 12.799-28.586 28.586v233.303c0 15.787 12.799 28.586 28.586 28.586h7.7"
                transform="translate(83.919 140.36)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0v-162.054c0-15.787-12.799-28.586-28.586-28.586l-277.93-.001"
                transform="translate(482.386 331)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0h237.418"
                transform="translate(186.168 394.281)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0h53.676"
                transform="translate(19.943 394.281)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0c0-13.371-10.84-24.211-24.211-24.211-13.371 0-24.21 10.84-24.21 24.211 0 13.371 10.839 24.21 24.21 24.21S0 13.371 0 0Z"
                transform="translate(154.105 430.835)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0v24.511l-22.32 4.698a53.63 53.63 0 0 1-3.053 7.361l12.463 19.109-17.331 17.332-19.109-12.463a53.75 53.75 0 0 1-7.361 3.052l-4.698 22.321H-85.92L-90.618 63.6a53.75 53.75 0 0 1-7.361-3.052l-19.109 12.463-17.332-17.332 12.463-19.109a53.75 53.75 0 0 1-3.052-7.361l-22.321-4.698V0l22.321-4.698a53.816 53.816 0 0 1 3.052-7.362l-12.463-19.108 17.332-17.331 19.109 12.463a53.63 53.63 0 0 1 7.361-3.053l4.698-22.32h24.511l4.698 22.32a53.63 53.63 0 0 1 7.361 3.053l19.109-12.463 17.331 17.331-12.463 19.108a53.695 53.695 0 0 1 3.053 7.362z"
                transform="translate(203.559 418.58)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M154.104 288.795H77.709v28.991h76.395z"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="m0 0 23.314 23.313a6.954 6.954 0 0 0 9.859 0L69.47-12.974a6.957 6.957 0 0 1 9.858 0l102.068 102.07h-12.407c-4 0-7.625 1.624-10.244 4.243a14.476 14.476 0 0 0-4.244 10.255c0 8 6.488 14.488 14.488 14.488h47.608c7.899 0 14.296-6.396 14.296-14.285V56.189c0-4.01-1.624-7.625-4.245-10.254a14.468 14.468 0 0 0-10.254-4.245c-8 0-14.498 6.489-14.498 14.499v12.397L79.328-53.971a6.944 6.944 0 0 0-9.849 0L33.173-17.674c-2.721 2.721-7.128 2.721-9.859 0l-61.547-61.557c-5.656-5.666-14.834-5.666-20.499 0a14.434 14.434 0 0 0-4.245 10.244c0 3.716 1.412 7.422 4.245 10.255l33.737 33.736"
                transform="translate(261.164 324.16)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0h142.143"
                transform="translate(294.58 190.482)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0h84.271"
                transform="translate(352.452 223.387)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0h52.184"
                transform="translate(77.71 260.31)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0v0c-7.009 0-12.691 5.682-12.691 12.691v120.172h25.382V12.691C12.691 5.682 7.01 0 0 0Z"
                transform="translate(251.164 7.5)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="m0 0-31.517-123.311a12.69 12.69 0 0 0-12.296-9.548h-2.594c-8.284 0-14.348 7.807-12.296 15.834l22.057 86.302"
                transform="translate(200.731 140.36)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0h2.595c8.284 0 14.347 7.808 12.296 15.834L-15.02 132.863h-28.793L-12.296 9.549A12.692 12.692 0 0 1 0 0Z"
                transform="translate(345.41 7.5)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0c0-25.393-20.587-45.974-45.979-45.974-3.561 0-7.031.407-10.361 1.171C-76.739-40.102-91.952-21.831-91.952 0c0 21.831 15.213 40.102 35.612 44.803 3.33.763 6.8 1.17 10.361 1.17C-20.587 45.973 0 25.392 0 0Z"
                transform="translate(175.871 140.36)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
              <path
                d="M0 0v-20.228"
                transform="translate(129.894 150.474)"
                fill="none"
                stroke="#0b99ff"
                stroke-width="15"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-dasharray="none"
                stroke-opacity=""
                data-original="#000000"
                opacity="1"
                className=""
              ></path>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}
export default Start;
