import "../../styles/createTask.css";
import "../../styles/tabs.css";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useContext, useEffect, useState } from "react";

import Header from "../header";
import TokenAvito from "../tokenAvito";
import TableProducts from "../tableProducts/tableProducts";
import { GlobalContext } from "../../store/GlobalContext";
import ScrollToTop from "../../methods/scrollToTop";
import { Api } from "../../methods/requests/Api";
import { Spinner } from "../../methods/spinner";

function CreateTask() {
  const {
    modal,
    setModal,
    setModalName,
    data,
    setAlert,
    setAlertName,
    setAlertBorder,
    allBasket,
    priceBasket,
    setPriceBasket,
    setCurrentTask,
  } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState(0);
  const body = document.querySelector("body");

  // Получение цены корзины
  const getPriceBasket = async () => {
    try {
      const response = await Api.sumBasket();
      setPriceBasket(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  // Открытие корзины в модальном окне
  const openBasket = (modalName) => {
    setModal(!modal);
    setModalName(modalName);
  };

  const toggleModal = async (modalName) => {
    const inputLink = document.querySelector(".announcement-link").value;

    const btn = new Spinner(document.querySelector("#addAnnouncement button"));
    btn.start();
    try {
      const response = await Api.getAnnouncementInfoUrl(inputLink);
      setCurrentTask(response.data.data.announcement);
      setModal(!modal);
      setModalName(modalName);
      if (modal === false) {
        body.style.overflow = "hidden";
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setAlert(true);
        setAlertName("Incorrect url");
        setAlertBorder("negative");
      }
      if (error.response.data.status === 404) {
        setAlert(true);
        setAlertName("Incorrect url");
        setAlertBorder("negative");
      }
    }
    btn.end();
  };

  useEffect(() => {
    getPriceBasket();
  }, [allBasket]);

  return (
    <div className="create-task">
      <ScrollToTop />
      <Header title={"Создать задачу"} />
      {data.avito_token && data.avito_token != 0 ? (
        <div className="component create-task__form">
          <h2>Создание задачи</h2>
          <form id="addAnnouncement">
            <input
              className="announcement-link"
              name="link"
              placeholder="Ссылка"
              required
            />
            <button
              className="button-default"
              type="button"
              onClick={() => toggleModal("Выбор объявлений")}
            >
              Добавить
            </button>
          </form>
        </div>
      ) : (
        <div className="components-double">
          <div className="component create-task__form">
            <h2>Создание задачи</h2>
            <form id="addAnnouncement">
              <input
                className="announcement-link"
                name="link"
                placeholder="Ссылка"
                required
              />
              <button
                className="button-default"
                type="button"
                onClick={() => toggleModal("Выбор объявлений")}
              >
                Добавить
              </button>
            </form>
          </div>
          <TokenAvito />
        </div>
      )}

      <Tabs
        onSelect={(index) => setActiveTab(index)}
        selectedIndex={activeTab ? activeTab : 0}
      >
        <TabList className="tabs">
          <Tab className="tabs-item">Выбор объявлений</Tab>
          <Tab className="tabs-item">Корзина задач</Tab>
        </TabList>
        <TabPanel>
          <TableProducts title="Выбор объявлений" />
        </TabPanel>
        <TabPanel>
          <TableProducts title="Корзина задач" />
        </TabPanel>
      </Tabs>
      {priceBasket ? (
        <div className="component promotion-cost">
          <h3>Стоимость продвижения - {priceBasket} ₽</h3>
          <button
            onClick={
              activeTab === 0
                ? () => setActiveTab(1)
                : () => openBasket("Оплата задачи")
            }
            className="button-active button-width_auto"
          >
            {activeTab === 0 ? "Перейти в корзину" : "Оформить заказ"}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
export default CreateTask;
