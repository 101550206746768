import { useEffect, useState, useRef, useContext } from "react";
import { Api } from "../../methods/requests/Api";
import { GlobalContext } from "../../store/GlobalContext";
import { calculationsPrice } from "../../methods/calculationsPrice";

const GetServices = (data) => {
  const {
    setCurrentSum,
    setOldSum,
    setEconomy,
    spiner,
    setSpiner,
    servicesPrices,
    setServicesPrices,
    currentTask,
  } = useContext(GlobalContext);
  const [service, setService] = useState("");

  // Получение данных об услугах
  const fetchServices = async () => {
    try {
      const response = await Api.indexService();
      setService(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Получение цен у услуг
  const getServicesPrices = async () => {
    try {
      setSpiner(true);
      if (data.id) {
        const response = await Api.getServicesPrices(data.id);
        setServicesPrices(response.data.data);
        setSpiner(false); 
      }
      if (data.avito_id) {
        const response = await Api.getServicesPrices(data.avito_id);
        setServicesPrices(response.data.data);
        setSpiner(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hasFetched = useRef(false);

  const getService = async () => {
    const idService = currentTask.service_id;
    const serviceItems = document.querySelectorAll(".improver-services__item");

    serviceItems.forEach((element) => {
      const serviceItemId = element.id;
      element.classList.remove("improver-services__item_active");
      if (idService) {
        if (idService == serviceItemId) {
          element.classList.add("improver-services__item_active");
        }
      } else {
        if (serviceItemId == 2) {
          element.classList.add("improver-services__item_active");
        }
      }
    });
  };

  const init = async () => {
    try {
      if (!hasFetched.current && data.id) {
        await fetchServices();
      }
      await getServicesPrices();
      hasFetched.current = true;
    } catch {}
  };

  // Смена активной услуги
  const toggleServices = (e) => {
    const h1 = document.getElementById("number-improver");
    const improverServices = document.querySelectorAll(
      ".improver-services__item"
    );
    const currentItem = e.target.closest(".improver-services__item");
    const currentItemNumber = currentItem.querySelector(
      ".improver-services__number"
    ).innerText;

    h1.innerText = "Увеличить количество просмотров : " + currentItemNumber;

    improverServices.forEach((item) => {
      item.classList.remove("improver-services__item_active");
    });
    currentItem.classList.add("improver-services__item_active");

    const currentPrice = currentItem
      .querySelector(".improver-services__price_current")
      .innerHTML.split(" ")[0];
    const oldPrice = currentItem
      .querySelector(".improver-services__price_crossed")
      .innerHTML.split(" ")[0];
    const currentPeriod = document.querySelector("input[type=range]").value;

    setCurrentSum(currentPeriod * currentPrice);
    setOldSum(currentPeriod * oldPrice);
    Math.round(
      setEconomy(currentPeriod * oldPrice - currentPeriod * currentPrice),
      0
    );
  };

  useEffect(() => {
    init();
  }, [data.id || data.avito_id]);

  useEffect(() => {
    getService();
    const active = document.querySelector(".improver-services__item_active");
    calculationsPrice(active, setCurrentSum, setOldSum, setEconomy);
  }, [servicesPrices]);

  return (
    <div className="improver-services">
      <h1 id="number-improver">Увеличить количество просмотров </h1>
      <div className="improver-services__list">
        {service &&
          servicesPrices &&
          service.map((item) => (
            <div
              className="improver-services__item"
              onClick={toggleServices}
              id={item.id}
              key={item.id}
            >
              <p
                className="improver-services__number"
                style={{ backgroundColor: `${item.color}` }}
              >
                {item.name}
              </p>
              <div className="improver-services__price">
                <p className="improver-services__price_current">
                  {servicesPrices[item.name].currentPrice} ₽
                </p>
                <p className="improver-services__price_crossed">
                  {servicesPrices[item.name].oldPrice} ₽
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default GetServices;
