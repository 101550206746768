import { useContext } from "react";
import "../styles/referal.css";
import { GlobalContext } from "../store/GlobalContext";

function Referral() {
  const { data, alert, setAlert, setAlertName, setAlertBorder } = useContext(GlobalContext);
  
  function copyReferalLink() {
    const text = `https://avibooster.ru/registration?token=${data.referral_link}`;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setAlert(true);
        setAlertName('Текст скопирован');
        setAlertBorder('positive')
      })
      .catch((error) => {
        alert("Ошибка при копировании текста", error);
      });
  }

  return (
    <div className="component referal">
      <h2>Пригласи друга</h2>
      <p>Бонусы рефералов: {data.bonus} ₽</p>
      <button className="button-default" onClick={copyReferalLink}>
        Скопировать ссылку
      </button>
    </div>
  );
}

export default Referral;
