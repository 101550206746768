import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

import "../styles/header.css";
import { Api } from "../methods/requests/Api";
import { GlobalContext } from "../store/GlobalContext";

function Header({ title }) {
  const { notifications, notificationsCount } = useContext(GlobalContext);

  function toggleNotification(e) {
    e.preventDefault();
    const vievedNotification = async () => {
      await Api.notificationVieved();
    };

    const componentEditProfile = document.querySelector(
      ".notification-content"
    );
    componentEditProfile.classList.toggle("hidden");

    vievedNotification();
  }

  const closeModal = () => {
    const componentEditProfile = document.querySelector(
      ".notification-content"
    );
    const hideNotification = (e) => {
      if (
        !e.target.closest(".notification-content") &&
        !e.target.closest(".notification")
      )
        componentEditProfile.classList.add("hidden");
    };

    window.addEventListener("click", hideNotification);
    window.removeEventListener("click", hideNotification);
  };

  // Закрытие модального окна
  useEffect(() => closeModal, []);
  return (
    <header className="component header">
      {title !== "Главная" && (
        <Link to="/home" className="button-width_auto header-back">
          <svg
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.992 7.712L0.24 3.96L3.992 0.207999L4.744 0.943999L1.688 3.96L4.744 6.976L3.992 7.712Z"
              fill="black"
            />
          </svg>
        </Link>
      )}
      {title === "Главная" && <div></div>}
      <h3 className="header-title">{title}</h3>
      <div className="notification" onClick={toggleNotification}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.42943 2.41295C6.55551 2.26248 6.61756 2.06857 6.60227 1.87286C6.58698 1.67714 6.49556 1.49523 6.34765 1.36616C6.19973 1.2371 6.00711 1.17117 5.81113 1.18253C5.61515 1.1939 5.43144 1.28165 5.29943 1.42695L4.00743 2.90695C3.27482 3.74652 2.86067 4.81692 2.83743 5.93095L2.78043 8.64995C2.77632 8.84886 2.8514 9.04126 2.98914 9.18482C3.12689 9.32838 3.31602 9.41134 3.51493 9.41545C3.71385 9.41956 3.90624 9.34448 4.0498 9.20674C4.19336 9.06899 4.27632 8.87986 4.28043 8.68095L4.33643 5.96295C4.35248 5.20068 4.63601 4.46831 5.13743 3.89395L6.42943 2.41295Z"
            fill="black"
            fillOpacity="0.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.23695 7.7C6.30411 6.63014 6.77647 5.62608 7.55787 4.89224C8.33928 4.15839 9.37098 3.74993 10.4429 3.75H10.9999V3C10.9999 2.73478 11.1053 2.48043 11.2928 2.29289C11.4804 2.10536 11.7347 2 11.9999 2C12.2652 2 12.5195 2.10536 12.7071 2.29289C12.8946 2.48043 12.9999 2.73478 12.9999 3V3.75H13.5569C14.6289 3.74993 15.6606 4.15839 16.442 4.89224C17.2234 5.62608 17.6958 6.63014 17.7629 7.7L17.9839 11.234C18.0682 12.5815 18.5206 13.88 19.2919 14.988C19.4513 15.2171 19.5487 15.4835 19.5746 15.7613C19.6005 16.0392 19.5541 16.319 19.4398 16.5736C19.3256 16.8281 19.1474 17.0488 18.9226 17.2142C18.6978 17.3795 18.434 17.4838 18.1569 17.517L14.7499 17.925V19C14.7499 19.7293 14.4602 20.4288 13.9445 20.9445C13.4288 21.4603 12.7293 21.75 11.9999 21.75C11.2706 21.75 10.5711 21.4603 10.0554 20.9445C9.53968 20.4288 9.24995 19.7293 9.24995 19V17.925L5.84295 17.516C5.56604 17.4827 5.30246 17.3784 5.0778 17.2131C4.85313 17.0478 4.67503 16.8273 4.5608 16.5729C4.44657 16.3184 4.40009 16.0388 4.42587 15.7611C4.45165 15.4834 4.54883 15.2171 4.70795 14.988C5.47925 13.8799 5.93168 12.5814 6.01595 11.234L6.23695 7.7ZM10.7499 19C10.7499 19.3315 10.8816 19.6495 11.1161 19.8839C11.3505 20.1183 11.6684 20.25 11.9999 20.25C12.3315 20.25 12.6494 20.1183 12.8838 19.8839C13.1183 19.6495 13.2499 19.3315 13.2499 19V18.25H10.7499V19Z"
            fill="black"
            fillOpacity="0.5"
          />
          <path
            d="M17.6425 1.35495C17.4927 1.48573 17.401 1.67065 17.3875 1.86905C17.374 2.06745 17.4398 2.26309 17.5705 2.41295L18.8625 3.89295C19.3638 4.46772 19.647 5.20046 19.6625 5.96295L19.7195 8.67996C19.7216 8.77845 19.743 8.87557 19.7826 8.96579C19.8221 9.056 19.8791 9.13754 19.9502 9.20575C20.0212 9.27395 20.1051 9.32749 20.1968 9.3633C20.2886 9.39911 20.3865 9.41649 20.485 9.41446C20.5835 9.41242 20.6806 9.391 20.7709 9.35143C20.8611 9.31186 20.9426 9.25491 21.0108 9.18383C21.079 9.11274 21.1326 9.02892 21.1684 8.93715C21.2042 8.84537 21.2216 8.74745 21.2195 8.64895L21.1625 5.93095C21.1393 4.81693 20.7251 3.74652 19.9925 2.90695L18.7005 1.42695C18.5698 1.27714 18.3848 1.1854 18.1864 1.1719C17.988 1.1584 17.7924 1.22424 17.6425 1.35495Z"
            fill="black"
            fillOpacity="0.5"
          />
        </svg>
        {notifications && notificationsCount != 0 ? (
          <p className="notification-quantity">{notificationsCount}</p>
        ) : (
          ""
        )}
      </div>
      {!(notifications && notifications.length === 0) ? (
        <div className="notification-content hidden">
          <h2>Уведомления</h2>
          <div className="notification-list">
            {notifications?.map((item) => (
              <div className="notification-list__item" key={item.id}>
                <div className="notification-item__title">
                  <h3>{item.title}</h3>
                  <p>{dateFormat(item.created_at, "dd.mm.yyyy HH:MM")}</p>
                </div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="notification-content notification-content__null hidden">
          <h3>У вас нет уведомлений</h3>
        </div>
      )}
    </header>
  );
}

export default Header;
