import React, { useEffect, useState } from "react";
import checkAuth from "../methods/requests/checkAuth";
import { Api } from "../methods/requests/Api";

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [modalName, setModalName] = useState("");
  const [modalUrl, setModalUrl] = useState("");
  const [modalData, setModalData] = useState("");

  const [alert, setAlert] = useState(false);
  const [alertName, setAlertName] = useState("");
  const [alertData, setAlertData] = useState({});
  const [alertBorder, setAlertBorder] = useState("negative");

  const [currentTask, setCurrentTask] = useState({});
  const [activeTaskId, setActiveTaskId] = useState(null);
  const [completedTaskId, setCompletedTaskId] = useState(null);
  const [selectTaskId, setTaskId] = useState({});
  const [announcementUrl, setAnnouncementUrl] = useState({});
  const [servicesPrices, setServicesPrices] = useState("");

  const [allAnnouncement, setAllAnnouncement] = useState([]);

  const [status, setStatus] = useState("");

  const [data, setData] = useState("");

  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState("");

  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState("");

  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState("0");

  const [authorizationHistory] = useState({});

  const [currentSum, setCurrentSum] = useState("0");
  const [oldSum, setOldSum] = useState("0");
  const [economy, setEconomy] = useState("0");

  const [priceBasket, setPriceBasket] = useState("");
  const [sum, setSum] = useState(0);
  const [allBasket, setAllBasket] = useState([]);
  const [taskInfo, setTaskInfo] = useState({});

  const [spiner, setSpiner] = useState(true);

  const [activeTasks, setActiveTasks] = useState(0);
  const [allTasks, setAllTasks] = useState(0);

  const getAccess = async () => {
    const authStatus = await checkAuth(setStatus, setData);
    showNotification(authStatus);
  };

  const showNotification = async (authStatus) => {
    if (authStatus !== 200) return;
    const response = await Api.notification();
    setNotifications(response.data.data);

    const responseCount = await Api.notificationСount();
    setNotificationsCount(responseCount.data.data.count);
  };

  useEffect(() => {
    getAccess();
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        modal,
        setModal,
        modalName,
        setModalName,
        modalUrl,
        setModalUrl,
        modalData,
        setModalData,

        alert,
        setAlert,
        alertName,
        setAlertName,
        alertData,
        setAlertData,
        alertBorder,
        setAlertBorder,

        currentTask,
        setCurrentTask,

        activeTaskId,
        setActiveTaskId,

        completedTaskId,
        setCompletedTaskId,

        selectTaskId,
        setTaskId,

        announcementUrl,
        setAnnouncementUrl,

        servicesPrices,
        setServicesPrices,

        allAnnouncement,
        setAllAnnouncement,

        status,
        setStatus,

        data,
        setData,

        users,
        setUsers,
        usersCount,
        setUsersCount,

        orders,
        setOrders,
        ordersCount,
        setOrdersCount,

        notifications,
        setNotifications,
        notificationsCount,
        setNotificationsCount,

        authorizationHistory,

        currentSum,
        setCurrentSum,
        oldSum,
        setOldSum,
        economy,
        setEconomy,


        sum,
        setSum,
        priceBasket,
        setPriceBasket,

        allBasket,
        setAllBasket,

        taskInfo,
        setTaskInfo,

        spiner,
        setSpiner,

        activeTasks, 
        setActiveTasks,

        allTasks, 
        setAllTasks,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
