import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomeScreen from "./screens/homeScreen";
import CreateTask from "./screens/createTask";
import MyTasks from "./screens/myTasks";
import PersonalAccount from "./screens/personalaccount";
import Login from "./screens/login";
import Registration from "./screens/registration";
import PasswordRecovery from "./screens/passwordRecovery";

import { RequireAuth } from "./hoc/RequireAuth";
import PageNotFound from "./screens/PageNotFound";
import Alert from "./alerts/alert";
import AdminPanel from "./screens/admin/adminPanel";
import VerifyEmail from "./screens/verifyEmail";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../store/GlobalContext";
import Landing from "./screens/landing/landing";

const Container = () => {
  const { status, data, modal } = useContext(GlobalContext);

  const html = document.querySelector("html");

  useEffect(() => {
    if (modal) {
      html.style = "scrollbar-gutter: auto;";
    } else {
      html.style = "scrollbar-gutter: stable;";
    }
  }, [modal]);

  return (
    // <div className="App">
    //   <BrowserRouter>
    //     <Routes>
    //       <Route path="/login" element={<Login />} />
    //       <Route path="/registration" element={<Registration />} />
    //       <Route path="/password-recovery" element={<PasswordRecovery />} />
    //       <Route path="/verifyemail" element={<VerifyEmail />} />

    //       <Route
    //         path="/account"
    //         element={
    //           <RequireAuth>
    //             <PersonalAccount />
    //           </RequireAuth>
    //         }
    //       />
    //       <Route
    //         path="/create-task"
    //         element={
    //           <RequireAuth>
    //             <CreateTask />
    //           </RequireAuth>
    //         }
    //       />
    //       <Route
    //         path="/my-tasks"
    //         element={
    //           <RequireAuth>
    //             <MyTasks />
    //           </RequireAuth>
    //         }
    //       />
    //       <Route
    //         path="/"
    //         element={
    //           <RequireAuth>
    //             <HomeScreen />
    //           </RequireAuth>
    //         }
    //       />
    //       <Route
    //         path="*"
    //         element={
    //           <RequireAuth>
    //             <PageNotFound />
    //           </RequireAuth>
    //         }
    //       />

    //       <Route
    //         path="/admin"
    //         element={
    //           <RequireAuth>
    //             <AdminPanel />
    //           </RequireAuth>
    //         }
    //       />
    //     </Routes>
    //     <Alert />
    //   </BrowserRouter>
    // </div>
    <div className="App">
      <BrowserRouter>
        {status && status !== 200 && (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/password-recovery" element={<PasswordRecovery />} />
            <Route path="/verifyemail" element={<VerifyEmail />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<Landing />} />
          </Routes>
        )}
        {status && status === 200 && data.role_id !== 1 && (
          <Routes>
            <Route
              path="/account"
              element={
                <RequireAuth>
                  <PersonalAccount />
                </RequireAuth>
              }
            />
            <Route
              path="/create-task"
              element={
                <RequireAuth>
                  <CreateTask />
                </RequireAuth>
              }
            />
            <Route
              path="/my-tasks"
              element={
                <RequireAuth>
                  <MyTasks />
                </RequireAuth>
              }
            />
            <Route
              path="/home"
              element={
                <RequireAuth>
                  <HomeScreen />
                </RequireAuth>
              }
            />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<Landing />} />
          </Routes>
        )}
        {data.role_id && data.role_id === 1 && (
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <AdminPanel />
                </RequireAuth>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        )}
        <Alert />
      </BrowserRouter>
    </div>
  );
};

export default Container;
