import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

import Header from "../header";
import TableProducts from "../tableProducts/tableProducts";

import "../../styles/header.css";
import "../../styles/my-tasks.css";
import ScrollToTop from "../../methods/scrollToTop";
import AllTasks from "../allTasks";
import ActiveTasks from "../activeTasks";

function MyTasks() {
  return (
    <div className="my-tasks">
      <ScrollToTop/>
      <Header title={"Мои задачи"} />
      <div className="dashboard">
        <div className="dashboard-item component dashboard-item__create-task">
          <Link to="/create-task">
            <button className="button-default">Создать новую задачу</button>
          </Link>
        </div>
        <AllTasks></AllTasks>
        <ActiveTasks></ActiveTasks>
        <div className="dashboard-item component dashboard-item__xls" href="">
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="26" height="25" rx="3.90625" fill="#0B99FF" />
            <path
              d="M4.91108 8.47005C3.86921 8.47005 3 7.62292 3 6.57878C3 5.53464 3.87 4.6875 4.91108 4.6875C5.41178 4.6875 5.89196 4.88676 6.24601 5.24144C6.60006 5.59613 6.79896 6.07718 6.79896 6.57878C6.79896 7.08037 6.60006 7.56143 6.24601 7.91611C5.89196 8.27079 5.41178 8.47005 4.91108 8.47005ZM21.902 7.83963H10.5747C10.4078 7.83963 10.2477 7.77321 10.1297 7.65498C10.0117 7.53675 9.94542 7.3764 9.94542 7.2092V5.94835C9.94542 5.78115 10.0117 5.6208 10.1297 5.50257C10.2477 5.38434 10.4078 5.31793 10.5747 5.31793H21.902C22.0689 5.31793 22.2289 5.38434 22.3469 5.50257C22.465 5.6208 22.5312 5.78115 22.5312 5.94835V7.2092C22.5312 7.3764 22.465 7.53675 22.3469 7.65498C22.2289 7.77321 22.0689 7.83963 21.902 7.83963ZM21.902 20.4481H10.5747C10.4078 20.4481 10.2477 20.3817 10.1297 20.2635C10.0117 20.1453 9.94542 19.9849 9.94542 19.8177V18.5569C9.94542 18.3897 10.0117 18.2293 10.1297 18.1111C10.2477 17.9929 10.4078 17.9264 10.5747 17.9264H21.902C22.0689 17.9264 22.2289 17.9929 22.3469 18.1111C22.465 18.2293 22.5312 18.3897 22.5312 18.5569V19.8177C22.5312 19.9849 22.465 20.1453 22.3469 20.2635C22.2289 20.3817 22.0689 20.4481 21.902 20.4481ZM21.902 14.1439H10.5747C10.4078 14.1439 10.2477 14.0775 10.1297 13.9592C10.0117 13.841 9.94542 13.6807 9.94542 13.5135V12.2526C9.94542 12.0854 10.0117 11.9251 10.1297 11.8068C10.2477 11.6886 10.4078 11.6222 10.5747 11.6222H21.902C22.0689 11.6222 22.2289 11.6886 22.3469 11.8068C22.465 11.9251 22.5312 12.0854 22.5312 12.2526V13.5135C22.5312 13.6807 22.465 13.841 22.3469 13.9592C22.2289 14.0775 22.0689 14.1439 21.902 14.1439Z"
              fill="white"
            />
            <path
              d="M4.91108 21.0938C3.86921 21.0938 3 20.2466 3 19.2025C3 18.1583 3.87 17.3112 4.91108 17.3112C5.41178 17.3112 5.89196 17.5105 6.24601 17.8651C6.60006 18.2198 6.79896 18.7009 6.79896 19.2025C6.79896 19.7041 6.60006 20.1851 6.24601 20.5398C5.89196 20.8945 5.41178 21.0937 4.91108 21.0938Z"
              fill="white"
            />
            <path
              d="M4.91108 14.8953C3.86921 14.8953 3 14.0482 3 13.004C3 11.9599 3.87 11.1128 4.91108 11.1128C5.41178 11.1128 5.89196 11.312 6.24601 11.6667C6.60006 12.0214 6.79896 12.5024 6.79896 13.004C6.79896 13.5056 6.60006 13.9867 6.24601 14.3414C5.89196 14.696 5.41178 14.8953 4.91108 14.8953Z"
              fill="white"
            />
          </svg>
          <p>Статистики в формате XLS</p>
          <a href="#" className="dashboard-item__text">
            Скачать
          </a>
        </div>
      </div>
      <Tabs>
        <TabList className="tabs">
          <Tab className="tabs-item">Активные задачи</Tab>
          <Tab className="tabs-item">Завершенные задачи</Tab>
        </TabList>

        <TabPanel>
          <TableProducts title="Активные задачи" />
        </TabPanel>
        <TabPanel>
          <TableProducts title="Завершенные задачи" />
        </TabPanel>
      </Tabs>
    </div>
  );
}
export default MyTasks;
