import { useState } from "react";

const TopUpBalance = () => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    event.preventDefault()
    setValue(event.target.value);
  }

  return (
    <div className="modal-content">
      <h1>Пополнение счёта</h1>
      <form>
        <h3>Выбор способа пополнения</h3>
        <select name="accountAvito" id="" className="select select-width_full">
          <option value="">Безналичный расчет</option>
          <option value="">Безналичный расчет</option>
          <option value="">Безналичный расчет</option>
          <option value="">Безналичный расчет</option>
        </select>
        <label htmlFor="sum">
          <span>
            <p>Укажите сумму</p>
          </span>
          <input
            value={value}
            onChange={handleChange}
            type="text"
            name="sum"
            required
          />
        </label>
        <div className="buttons-row">
          <button onClick={handleChange} className="button-balance button-default" value='5000'>5000 ₽</button>
          <button onClick={handleChange} className="button-balance button-default" value='10000'>10000 ₽</button>
          <button onClick={handleChange} className="button-balance button-default" value='25000'>25000 ₽</button>
          <button onClick={handleChange} className="button-balance button-default" value='50000'>50000 ₽</button>
        </div>
        <button className="button-active">Пополнить счёт</button>
      </form>
    </div>
  );
};

export default TopUpBalance;
