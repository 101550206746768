import { useContext } from "react";
import { GlobalContext } from "../../store/GlobalContext";
import { ApiAdmin } from "../screens/admin/methods/requests/ApiAdmin";

const AddRole = () => {
  const {
    setModal,
    setAlert,
    setAlertBorder,
    setAlertName,
  } = useContext(GlobalContext);

  // Добавление роли
  const addRole = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target.closest("#addRole"));
    const role = formData.get("role");
    try {
      await ApiAdmin.addRoles(role);
      setModal(false);
      setAlert(true);
      setAlertName("Добавление роли");
      setAlertBorder("positive");
    } catch (err) {
        console.log(err);
    }
  };

  return (
    <div className="modal-content">
      <h1>Добавление роли</h1>
      <form id="addRole">
        <input placeholder="Название роли" name="role"/>
        <button className="button-default" onClick={addRole}>Добавить</button>
      </form>
    </div>
  );
};

export default AddRole;
