import { useContext } from "react";
import { GlobalContext } from "../../store/GlobalContext";

const HistoryTopUpBalance = () => {
  const body = document.querySelector("body");
  const { modal, setModalName, modalData } = useContext(GlobalContext);

  return (
    <div className="modal-content">
      <h1>Зачисление средств</h1>
      <p>Дата: 01.01.2024</p>
      <p>Вид оплаты: безналичный расчёт</p>
      <p>Сумма: {modalData.value_total} рублей</p>
    </div>
  );
};

export default HistoryTopUpBalance;
