import "../styles/support.css";

function Support() {
  const supportLinks = document.querySelector(".support-links");

  function supportToggle() {
    supportLinks.classList.toggle("support-links_active");
  }
  return (
    <div className="support">
      <div className="support-links">
        <a className="support-links__item" href="">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.00002 0.666626C4.40002 0.666626 0.666687 4.39996 0.666687 8.99996C0.666687 13.6 4.40002 17.3333 9.00002 17.3333C13.6 17.3333 17.3334 13.6 17.3334 8.99996C17.3334 4.39996 13.6 0.666626 9.00002 0.666626ZM12.8667 6.33329C12.7417 7.64996 12.2 10.85 11.925 12.325C11.8084 12.95 11.575 13.1583 11.3584 13.1833C10.875 13.225 10.5084 12.8666 10.0417 12.5583C9.30835 12.075 8.89169 11.775 8.18335 11.3083C7.35835 10.7666 7.89169 10.4666 8.36669 9.98329C8.49169 9.85829 10.625 7.91663 10.6667 7.74163C10.6725 7.71512 10.6717 7.6876 10.6644 7.66146C10.6572 7.63532 10.6436 7.61135 10.625 7.59163C10.575 7.54996 10.5084 7.56663 10.45 7.57496C10.375 7.59163 9.20835 8.36663 6.93335 9.89996C6.60002 10.125 6.30002 10.2416 6.03335 10.2333C5.73335 10.225 5.16669 10.0666 4.74169 9.92496C4.21669 9.75829 3.80835 9.66663 3.84169 9.37496C3.85835 9.22496 4.06669 9.07496 4.45835 8.91663C6.89169 7.85829 8.50835 7.15829 9.31669 6.82496C11.6334 5.85829 12.1084 5.69163 12.425 5.69163C12.4917 5.69163 12.65 5.70829 12.75 5.79163C12.8334 5.85829 12.8584 5.94996 12.8667 6.01663C12.8584 6.06663 12.875 6.21663 12.8667 6.33329Z"
              fill="#0B99FF"
            />
          </svg>
          Telegram
        </a>
        <a className="support-links__item" href="">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_614_1292)">
              <path
                d="M1.01209 12.3564C1.01153 14.3728 1.54253 16.3418 2.55221 18.0772L0.915527 24.0067L7.03103 22.4156C8.72249 23.3293 10.6177 23.8081 12.5435 23.8082H12.5486C18.9062 23.8082 24.0815 18.6749 24.0842 12.3654C24.0855 9.30798 22.8866 6.43301 20.7084 4.27003C18.5306 2.10724 15.6342 0.915519 12.5481 0.914124C6.18972 0.914124 1.01481 6.04715 1.01218 12.3564"
                fill="url(#paint0_linear_614_1292)"
              />
              <path
                d="M0.600313 12.3527C0.599656 14.4417 1.14969 16.481 2.19537 18.2786L0.5 24.4207L6.83478 22.7726C8.58022 23.7168 10.5454 24.2147 12.5451 24.2154H12.5502C19.136 24.2154 24.4972 18.8975 24.5 12.3621C24.5011 9.19488 23.2591 6.21656 21.0031 3.97609C18.7468 1.73591 15.7468 0.501302 12.5502 0.5C5.96337 0.5 0.602938 5.81721 0.600313 12.3527ZM4.37291 17.969L4.13637 17.5965C3.14206 16.0277 2.61725 14.2149 2.618 12.3534C2.62006 6.9213 7.07544 2.50186 12.554 2.50186C15.2071 2.50298 17.7005 3.52921 19.5759 5.39116C21.4512 7.2533 22.4831 9.72865 22.4824 12.3614C22.48 17.7935 18.0245 22.2135 12.5502 22.2135H12.5463C10.7638 22.2126 9.01569 21.7376 7.49113 20.84L7.12831 20.6265L3.36912 21.6045L4.37291 17.969Z"
                fill="url(#paint1_linear_614_1292)"
              />
              <path
                d="M9.56334 7.39746C9.33965 6.90416 9.10425 6.89421 8.89153 6.88556C8.71734 6.87811 8.51821 6.87867 8.31928 6.87867C8.12015 6.87867 7.79662 6.953 7.52315 7.24928C7.2494 7.54583 6.47803 8.26249 6.47803 9.72007C6.47803 11.1777 7.548 12.5864 7.69715 12.7843C7.8465 12.9817 9.76275 16.0686 12.7976 17.2562C15.3199 18.243 15.8332 18.0468 16.3806 17.9973C16.9281 17.948 18.1472 17.2808 18.3959 16.589C18.6448 15.8973 18.6448 15.3043 18.5702 15.1804C18.4956 15.057 18.2964 14.9829 17.9978 14.8348C17.6992 14.6866 16.2312 13.9698 15.9576 13.871C15.6838 13.7722 15.4848 13.7229 15.2857 14.0195C15.0865 14.3157 14.5147 14.9829 14.3405 15.1804C14.1664 15.3785 13.9921 15.4031 13.6936 15.255C13.3948 15.1063 12.4332 14.7938 11.2924 13.7846C10.4047 12.9993 9.8055 12.0296 9.63131 11.7329C9.45712 11.4367 9.61265 11.2762 9.76237 11.1285C9.89653 10.9958 10.0611 10.7826 10.2105 10.6096C10.3594 10.4366 10.4091 10.3132 10.5086 10.1156C10.6083 9.91783 10.5584 9.74481 10.4839 9.59663C10.4091 9.44844 9.82884 7.98323 9.56334 7.39746Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_614_1292"
                x1="1159.35"
                y1="2310.17"
                x2="1159.35"
                y2="0.914124"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1FAF38" />
                <stop offset="1" stopColor="#60D669" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_614_1292"
                x1="1200.5"
                y1="2392.57"
                x2="1200.5"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F9F9F9" />
                <stop offset="1" stopColor="white" />
              </linearGradient>
              <clipPath id="clip0_614_1292">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.5 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          WhatsApp
        </a>
        <a className="support-links__item" href="mailto:support@gmail.com">
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 16C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.316071 14.5587 0 13.7956 0 13V3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H17C17.7956 0 18.5587 0.316071 19.1213 0.87868C19.6839 1.44129 20 2.20435 20 3V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H3ZM5.625 4.22C5.52313 4.13302 5.40483 4.06739 5.27711 4.027C5.1494 3.98662 5.01487 3.97231 4.88151 3.98491C4.74816 3.99752 4.61869 4.03679 4.50081 4.1004C4.38292 4.164 4.27901 4.25064 4.19525 4.35517C4.11149 4.4597 4.04959 4.58 4.01321 4.70891C3.97684 4.83783 3.96673 4.97274 3.9835 5.10564C4.00026 5.23853 4.04356 5.36671 4.11081 5.48255C4.17806 5.59839 4.2679 5.69954 4.375 5.78L8.125 8.781C8.65709 9.20701 9.31838 9.43913 10 9.43913C10.6816 9.43913 11.3429 9.20701 11.875 8.781L15.625 5.781C15.7276 5.69892 15.813 5.59745 15.8763 5.48237C15.9396 5.3673 15.9797 5.24087 15.9942 5.11031C16.0087 4.97975 15.9973 4.84762 15.9607 4.72145C15.9241 4.59529 15.8631 4.47756 15.781 4.375C15.6989 4.27244 15.5974 4.18705 15.4824 4.1237C15.3673 4.06035 15.2409 4.02029 15.1103 4.00581C14.9798 3.99132 14.8476 4.00269 14.7215 4.03927C14.5953 4.07585 14.4776 4.13692 14.375 4.219L10.625 7.219C10.4476 7.361 10.2272 7.43838 10 7.43838C9.77279 7.43838 9.55236 7.361 9.375 7.219L5.625 4.22Z"
              fill="#0B99FF"
            />
          </svg>
          support@gmail.com
        </a>
        <a className="support-links__item" href="tel:+79123456789">
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5559 10.906L12.1009 11.359C12.1009 11.359 11.0179 12.435 8.06288 9.49698C5.10788 6.55898 6.19088 5.48298 6.19088 5.48298L6.47688 5.19698C7.18388 4.49498 7.25088 3.36698 6.63388 2.54298L5.37388 0.859979C4.60988 -0.160021 3.13488 -0.29502 2.25988 0.57498L0.68988 2.13498C0.25688 2.56698 -0.0331206 3.12498 0.00187944 3.74498C0.0918794 5.33198 0.809879 8.74498 4.81388 12.727C9.06088 16.949 13.0459 17.117 14.6749 16.965C15.1909 16.917 15.6389 16.655 15.9999 16.295L17.4199 14.883C18.3799 13.93 18.1099 12.295 16.8819 11.628L14.9719 10.589C14.1659 10.152 13.1859 10.28 12.5559 10.906Z"
              fill="#0B99FF"
            />
          </svg>
          +79123456789
        </a>
      </div>
      <span className="span-row">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.72 12.7602C17.07 11.9102 17.26 11.0002 17.26 10.0002C17.26 9.28019 17.15 8.59019 16.96 7.95019C16.31 8.10019 15.63 8.18019 14.92 8.18019C13.466 8.18175 12.0329 7.83371 10.7415 7.16541C9.45018 6.49711 8.33843 5.52815 7.5 4.34019C6.60396 6.51093 4.91172 8.25684 2.77 9.22019C2.73 9.47019 2.73 9.74019 2.73 10.0002C2.73 10.9549 2.91804 11.9003 3.2834 12.7823C3.64875 13.6643 4.18425 14.4658 4.85933 15.1409C6.22272 16.5042 8.07188 17.2702 10 17.2702C11.05 17.2702 12.06 17.0402 12.97 16.6302C13.54 17.7202 13.8 18.2602 13.78 18.2602C12.14 18.8102 10.87 19.0802 10 19.0802C7.58 19.0802 5.27 18.1302 3.57 16.4202C2.53505 15.3901 1.76627 14.1237 1.33 12.7302H0V8.18019H1.09C1.42024 6.5727 2.17949 5.0846 3.28719 3.87378C4.39489 2.66296 5.80971 1.7746 7.38153 1.30295C8.95335 0.8313 10.6235 0.793963 12.2149 1.1949C13.8062 1.59583 15.2593 2.42008 16.42 3.58019C17.6798 4.83577 18.5393 6.43648 18.89 8.18019H20V12.7302H19.94L16.38 16.0002L11.08 15.4002V13.7302H15.91L16.72 12.7602ZM7.27 9.77019C7.57 9.77019 7.86 9.89019 8.07 10.1102C8.28105 10.323 8.39947 10.6105 8.39947 10.9102C8.39947 11.2099 8.28105 11.4974 8.07 11.7102C7.86 11.9202 7.57 12.0402 7.27 12.0402C6.64 12.0402 6.13 11.5402 6.13 10.9102C6.13 10.2802 6.64 9.77019 7.27 9.77019ZM12.72 9.77019C13.35 9.77019 13.85 10.2802 13.85 10.9102C13.85 11.5402 13.35 12.0402 12.72 12.0402C12.09 12.0402 11.58 11.5402 11.58 10.9102C11.58 10.6078 11.7001 10.3179 11.9139 10.1041C12.1277 9.8903 12.4177 9.77019 12.72 9.77019Z"
            fill="#0B99FF"
          />
        </svg>

        <p className="support-title" onClick={supportToggle}>
          Тех. поддержка
        </p>
      </span>
    </div>
  );
}

export default Support;
