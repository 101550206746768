import axios from "axios";
import { getCookie } from "../../../../../methods/getCookie";

export class ApiAdmin {
  static auth() {
    return {
      headers: {
        Authorization: "Bearer " + getCookie("currentToken"),
      },
    };
  }

  // Переменная для api + route
  static baseUrl(route) {
    return "https://api.avibooster.ru/api/" + route;
  }

  // Все пользователи
  static async allUsers() {
    const response = await axios.get(this.baseUrl("users/"), this.auth());
    return response;
  }

//   Удалить пользователя
    static async deleteUser(idUser) {
      const response = await axios.delete(this.baseUrl("users/" + idUser), this.auth());
      return response;
    }

  // Все заказы
  static async allOrders() {
    const response = await axios.get(this.baseUrl("orders"), this.auth());
    return response;
  }

  // Все роли
  static async allRoles() {
    const response = await axios.get(this.baseUrl("roles"), this.auth());
    return response;
  }

  // Добавление роли
  static async addRoles(role) {
    const response = await axios.post(this.baseUrl("roles"), {
        name: role
    }, this.auth());
    return response;
  }

  // Удаление роли
  static async deleteRole(idRole) {
    const response = await axios.delete(this.baseUrl("roles/" + idRole), this.auth());
    return response;
  }
}
