import React, { useContext, useEffect, useState } from "react";

import { GlobalContext } from "../../store/GlobalContext";
import GetServices from "./getServices";
import { calculationsPrice } from "../../methods/calculationsPrice";
import { Api } from "../../methods/requests/Api";
import { Spinner } from "../../methods/spinner";

const Improver = () => {
  const {
    currentSum,
    economy,
    setModal,
    setAlert,
    setAlertBorder,
    setAlertName,
    setPriceBasket,
    currentTask,
    setCurrentSum,
    setOldSum,
    setEconomy,
    spiner,
    servicesPrices
  } = useContext(GlobalContext);
  const [valuePromotion, setValuePromotion] = useState(15);
  const body = document.querySelector("body");

  const handleChange = (event) => {
    setValuePromotion(event.target.value);
  };

  // Дополнительная информация о продвижении
  function toggleInfoPromotion() {
    const componentInfoToken = document.querySelector(".info-promotion");
    componentInfoToken.classList.toggle("hidden");
  }

  // Редактирование задачи
  const editTask = async () => {
    const btn = new Spinner(
      document.querySelector(".payment-row .button-active")
    );
    btn.start();
    try {
      const service_id = document.querySelector(
        ".improver-services__item_active"
      ).id;
      const duration = valuePromotion;

      const taskInfo = {
        id: currentTask.id,
        service_id: service_id,
        duration: duration,
      };

      await Api.editTask(taskInfo);

      setModal(false);
      body.style.overflow = "auto";
      setAlert(true);
      setAlertName("Данные успешно обновлены");
      setAlertBorder("positive");
    } catch (err) {
      console.log(err);
    }
    btn.end();
  };

  // Создание задачи
  const createTask = async () => {
    const btn = new Spinner(
      document.querySelector(".payment-row .button-active")
    );
    btn.start();
    try {
      const service_id = document.querySelector(
        ".improver-services__item_active"
      ).id;
      const duration = valuePromotion;
      const avito_id = document.querySelector("#avito-advertisement").innerHTML;
      const avito_link = document.querySelector(".improver-header__text").href;
      const title = document.querySelector(".improver-header__text").innerHTML;
      const image = currentTask.image;
      // const image = document.querySelector(".improver-header__img").src;
      const address = document.querySelector("#announcement-address").innerHTML;
      const price_product = document.querySelector(
        ".improver-header__text_price"
      ).innerHTML;
      const category = document.querySelector(
        "#announcement-category"
      ).innerHTML;
      const taskInfo = {
        service_id: service_id,
        duration: duration,
        avito_id: avito_id,
        avito_link: avito_link,
        title: title,
        image: image,
        address: address,
        price_product: price_product.split(" ")[0],
        category: category,
      };
      await Api.createTask(taskInfo);

      const response = await Api.sumBasket();
      setPriceBasket(response.data.data);

      setModal(false);
      body.style.overflow = "auto";
      setAlert(true);
      setAlertName("Задача добавлена");
      setAlertBorder("positive");
    } catch (err) {
      console.log(err);
    }
    btn.end();
  };

  const getValuePromotion = () => {
    if (currentTask.duration) {
      setValuePromotion(currentTask.duration);
    } else {
      setValuePromotion(15);
    }
  };

  // Кастомный ползунок
  const rangeInputSlider = () => {
    const rangeThumb = document.getElementById("range-thumb"),
      rangeLine = document.getElementById("range-line"),
      rangeInput = document.getElementById("range-input"),
      arrow = document.querySelector(".arrow"),
      thumbPosition = rangeInput.value / rangeInput.max,
      space = rangeInput.offsetWidth - rangeThumb.offsetWidth;

    // rangeThumb.style.left = thumbPosition * space + "px";
    rangeLine.style.width = (rangeInput.value * 100) / rangeInput.max + "%";
    // arrow.style.left =
    //   (currentTask.promotionPeriod * 100) / rangeInput.max + "%";

    // const thumbPosition = rangeInput.value / rangeInput.max,
    //   space = rangeInput.offsetWidth - rangeThumb.offsetWidth;
    rangeThumb.style.left = thumbPosition * space + "px";
    rangeLine.style.width = rangeInput.value * 3.33 + "%";
    arrow.style.left = (rangeInput.value * 100) / rangeInput.max + "%";

    rangeInput.addEventListener("input", rangeInputSlider);
  };

  useEffect(() => {
    rangeInputSlider();
    const activeService = document.querySelector(
      ".improver-services__item_active"
    );
    if (activeService) {
      calculationsPrice(activeService, setCurrentSum, setOldSum, setEconomy);
    }
  }, [valuePromotion, servicesPrices]);

  useEffect(() => {
    // setSpiner(true)
    getValuePromotion();
    // console.log(currentTask);
    
  }, [currentTask]);

  return (
    <div className="modal-content">
      {spiner ? 
        <div className="loader-wrapper">
          <div className="skeleton-improver">
            <div className="skeleton-improver__header">
              <div className="block pulsate"></div>
              <div className="skeleton-improver__description">
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
                <div className="block pulsate"></div>
              </div>
            </div>
            <div className="block pulsate"></div>
            <div className="skeleton-improver__services">
              <div className="block pulsate"></div>
              <div className="block pulsate"></div>
              <div className="block pulsate"></div>
              <div className="block pulsate"></div>
            </div>
            <div className="skeleton-improver__header">
              <div className="block pulsate"></div>
              <div className="block pulsate skeleton_max-width"></div>

            </div>
            <div className="skeleton-improver__payment">
              <div className="block pulsate"></div>
              <div className="block pulsate"></div>
            </div>
          </div>
        </div>
      : ''  
    }

      <div className="improver-header">
        {/* <img
          className="improver-header__img"
          alt="Фото объявления"
          src={currentTask.image}
        /> */}
        <a href={currentTask.url} className="improver-header__text">
          {currentTask.title}
        </a>
        <div className="improver-header__text_small">
          <p>
            ID:{" "}
            <span id="avito-advertisement">
              {currentTask.avito_id ? currentTask.avito_id : currentTask.id}
            </span>
          </p>
          <p id="announcement-address">{currentTask.address}</p>
          <p className="improver-header__text_price">
            {currentTask.price_product
              ? currentTask.price_product
              : currentTask.price}{" "}
            ₽
          </p>
          <p id="announcement-category">{currentTask.category}</p>
        </div>
      </div>
      <GetServices
        id={currentTask.avito_id ? currentTask.avito_id : currentTask.id}
      />
      {valuePromotion ? (
        <h1>
          Срок продвижения : {valuePromotion + " "}
          {/* {handleChange ? value : currentTask.promotionPeriod}{" "} */}
          {valuePromotion === 1
            ? "день"
            : valuePromotion === 2
            ? "дня"
            : valuePromotion === 3
            ? "дня"
            : valuePromotion === 4
            ? "дня"
            : "дней"}
        </h1>
      ) : (
        <h1>Выберите срок продвижения</h1>
      )}
      <div className="modal-range">
        <div className="range">
          <div className="range__content">
            <div className="range__slider">
              <div className="range__slider-line" id="range-line"></div>
            </div>

            <div className="range__thumb" id="range-thumb">
              <div className="arrow">
                <svg
                  width="19"
                  height="26"
                  viewBox="0 0 19 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.8351 23.3259L11.8351 23.3259L11.8367 23.3216L18.1162 6.12073C18.1164 6.12015 18.1166 6.11958 18.1168 6.11901C18.299 5.62662 18.3909 5.07964 18.3909 4.53147C18.3909 3.98325 18.299 3.43623 18.1168 2.9438C17.9319 2.43826 17.652 1.98111 17.2789 1.64485C16.9023 1.30544 16.4319 1.09346 15.9158 1.09974L3.36125 1.09974C2.84511 1.09346 2.37476 1.30544 1.99814 1.64485C1.62508 1.98105 1.34524 2.43811 1.16035 2.94356C0.977901 3.43576 0.885719 3.98263 0.885423 4.53078C0.885132 5.0791 0.976783 5.6263 1.15876 6.11896C1.15893 6.11941 1.15909 6.11986 1.15926 6.12031L7.4404 23.3216L7.44039 23.3216L7.44197 23.3259C7.62925 23.8287 7.90961 24.2826 8.281 24.6179C8.65664 24.9569 9.12435 25.1707 9.63853 25.1707C10.1527 25.1707 10.6204 24.9569 10.9961 24.6179C11.3674 24.2826 11.6478 23.8287 11.8351 23.3259Z"
                    fill="#36B14A"
                    stroke="white"
                    strokeWidth="1.41463"
                  />
                </svg>
              </div>
            </div>
            <input
              type="range"
              className="range__input"
              name="promotion-period"
              id="range-input"
              min="1"
              max="30"
              value={valuePromotion}
              onChange={handleChange}
              step="1"
            />
          </div>
        </div>
        <div className="modal-range__numbers">
          <p>1</p>
          <p>30</p>
        </div>
      </div>
      <div className="info-promotion component hidden">
        <p>
          Экономия от стандартных рекламных инструментов Авито. Если Вы уже
          используете продвижение на площадке, то усилитель повысит
          результативность объявления
        </p>
      </div>
      <div className="payment">
        <div className="payment-sale">
          <h3>Ваша экономия составит: {economy} ₽</h3>
          <div className="info-promotion__button" onClick={toggleInfoPromotion}>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 16C8.9851 16.0013 9.96075 15.8079 10.8709 15.4309C11.781 15.0539 12.6076 14.5007 13.3033 13.8033C14.0007 13.1076 14.5539 12.281 14.9309 11.3709C15.3079 10.4607 15.5013 9.4851 15.5 8.5C15.5013 7.51491 15.3078 6.53927 14.9308 5.62917C14.5539 4.71906 14.0007 3.89242 13.3033 3.19676C12.6076 2.49927 11.781 1.94613 10.8709 1.56914C9.96075 1.19215 8.9851 0.99873 8 1.00001C7.01491 0.99875 6.03927 1.19218 5.12917 1.56917C4.21906 1.94616 3.39242 2.49928 2.69676 3.19676C1.99928 3.89242 1.44616 4.71906 1.06917 5.62917C0.692176 6.53927 0.49875 7.51491 0.500006 8.5C0.49873 9.4851 0.692146 10.4607 1.06914 11.3709C1.44613 12.281 1.99927 13.1076 2.69676 13.8033C3.39242 14.5007 4.21906 15.0539 5.12917 15.4308C6.03927 15.8078 7.01491 16.0013 8 16Z"
                stroke="#0992DA"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 3.625C8.24864 3.625 8.4871 3.72377 8.66291 3.89959C8.83873 4.0754 8.9375 4.31386 8.9375 4.5625C8.9375 4.81114 8.83873 5.0496 8.66291 5.22541C8.4871 5.40123 8.24864 5.5 8 5.5C7.75136 5.5 7.5129 5.40123 7.33709 5.22541C7.16127 5.0496 7.0625 4.81114 7.0625 4.5625C7.0625 4.31386 7.16127 4.0754 7.33709 3.89959C7.5129 3.72377 7.75136 3.625 8 3.625Z"
                fill="#0992DA"
              />
              <path
                d="M8.1875 12.25V7H7.4375M6.875 12.25H9.5"
                stroke="#0992DA"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="payment-row">
          <h2>Стоимость продвижения: {currentSum} ₽ </h2>
          {currentTask.duration && currentTask.duration ? (
            <button
              className="button-active button-width_auto"
              onClick={editTask}
            >
              Редактировать задачу
            </button>
          ) : (
            <button
              className="button-active button-width_auto"
              onClick={createTask}
            >
              Создать задачу
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Improver;
